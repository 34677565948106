import { ObjectExtensions } from './object-extensions';

export type NonEmptyArray<T> = [T, ...T[]];

/**
 * Namespace which extends Array functionality.
 */
export namespace ArrayExtensions {
    /**
     * @description Remove duplicate elements in the array.
     * @param {T[]} value The array to examine.
     * @returns {T[]} An array with only unique elements.
     */
    export function unique<T>(value: T[]): T[] {
        if (!hasElements(value)) {
            return [];
        }

        return Array.from(new Set(value));
    }

    /**
     * @description Verifies whether the object array has elements.
     * @param {ReadonlyArray<T>} value The array to examine.
     * @returns {boolean} True if the object has at least one element in the array, false otherwise (if array is not defined or empty).
     */
    export function hasElements<T>(value: NonEmptyArray<T> | T[] | null | undefined): value is NonEmptyArray<T> {
        return !ObjectExtensions.isNullOrUndefined(value) && value.length > 0;
    }

    /**
     * @description Checks if all elements satisfy given condition.
     * @param {T[]} value The array to examine.
     * @param {(value: T, index: number, array: T[]) => boolean} predicate The function which checks an element.
     * @returns {boolean} True if the array is empty or all the inner elements satisfy given condition,
     * false if at least one element does not pass the condition.
     */
    export function all<T>(value: T[] | null | undefined, predicate: (value: T, index: number, array: T[]) => boolean): boolean {
        if (!hasElements(value)) {
            return true;
        }

        return value.filter(predicate).length === value.length;
    }
}