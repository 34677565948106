import { CacheType, IAction, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, ICreateActionContext } from '@msdyn365-commerce/core';
import { InvoicePaidStatus, InvoiceSearchCriteria, InvoiceType, IQueryResultSettings, OrderInvoice, SalesOrdersDataActions } from '@msdyn365-commerce/retail-proxy';
import { getCurrentAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/BusinessPartnersDataActions.g';
import getCustomer, { GetCustomerInput } from '../get-customer';
import { QueryResultSettingsProxy } from '../utilities/QueryResultSettingsProxy';

const getDefaultSearchCriteria = (): InvoiceSearchCriteria => {
    return {
        InvoicePaidStatusValues: [InvoicePaidStatus.None]
    };
};

/**
 * Input class for get invoice data action
 */
export class GetInvoicesInput implements IActionInput {
    public readonly searchCriteria: InvoiceSearchCriteria;
    public readonly queryResultSettings: IQueryResultSettings;

    constructor(searchCriteria: InvoiceSearchCriteria, queryResultSettings: IQueryResultSettings) {
        this.searchCriteria = searchCriteria;
        this.queryResultSettings = queryResultSettings;
    }

    public getCacheKey = () => 'BusinessPartnerInvoices';
    public getCacheObjectType = () => 'OrderInvoice';
    public dataCacheType = (): CacheType => 'none';
}

export const CreateGetInvoicesInput = (inputData: ICreateActionContext): IActionInput => {
    const { requestContext } = inputData;

    // Check if the user is authenticated.
    if (!requestContext.user.isAuthenticated) {
        throw new Error('User is not logged in.');
    }

    const queryResultSettings: IQueryResultSettings = QueryResultSettingsProxy.fromInputData(inputData).QueryResultSettings;

    return new GetInvoicesInput(getDefaultSearchCriteria(), queryResultSettings);
};

export async function getInvoices(input: GetInvoicesInput, context: IActionContext): Promise<OrderInvoice[]> {
    if (!context?.requestContext?.user?.isAuthenticated) {
        throw new Error('Unable to get list of invoices. User is not authenticated.');
    }

    input.searchCriteria.InvoiceTypeValues = [InvoiceType.SalesOrderInvoice];

    const businessPartner = await getCurrentAsync({ callerContext: context });
    input.searchCriteria.BusinessPartnerId = businessPartner.BusinessPartnerId;

    const customer = await getCustomer(new GetCustomerInput(context.requestContext.apiSettings), context);
    input.searchCriteria.CustomerId = customer.AccountNumber;

    return SalesOrdersDataActions.getInvoicesAsync(
        { callerContext: context, queryResultSettings: input.queryResultSettings },
        input.searchCriteria);
}

export const getInvoicesDataAction =  createObservableDataAction({
    id: 'get-invoices',
    action: <IAction<OrderInvoice[]>>getInvoices,
    input: CreateGetInvoicesInput
});

export default getInvoicesDataAction;