import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { BusinessPartnerUser, NullResult } from '@msdyn365-commerce/retail-proxy';
import { deleteAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/BusinessPartnerUsersDataActions.g';
/**
 * Input class for DeleteOrganizationUser data action
 */
export class DeleteOrganizationUserInput implements IActionInput {
    public user: BusinessPartnerUser;

    constructor(newUser: BusinessPartnerUser) {
        this.user = newUser;
    }

    public getCacheKey = () => `DeleteOrganizationUser`;
    public getCacheObjectType = () => 'DeleteOrganizationUser';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * Input class for DeleteOrganizationUser data action
 */
export const createDeleteOrganizationUserInput = (inputData: ICreateActionContext<IGeneric<IAny>>, newUser: BusinessPartnerUser): IActionInput => {
    const { requestContext } = inputData;

    // Check if the user is authenticated.
    if (!requestContext.user.isAuthenticated) {
        throw new Error('User is not logged in.');
    }

    return new DeleteOrganizationUserInput(newUser);
};

/**
 * The DeleteOrganizationUser data action
 * Calls getCurrent() to get a businessPartnerId from the user requesting the action.
 * It then maps the newUsers' businessPartnerId to the same as the above user.
 * Calls the createBusinessPartner to then create a new entitiy in the database with the
 * information passed into through newUser
 */
export async function deleteOrganizationUser(input: DeleteOrganizationUserInput, ctx: IActionContext): Promise<NullResult> {
    const { user } = input;

    return deleteAsync({ callerContext: ctx}, user)
        .catch(error => {
            console.log(error);
            ctx.telemetry.debug('[Delete-organization-user] Unable to delete BusinessPartnerUser account');
            throw new Error('[Delete-organization-user] Unable to delete BusinessPartnerUser account');
        });
}

/**
 * The DeleteOrganizationUser data action
 * Calls the read RetailServer API to get the BusinessPartnerId from the admin
 * account and Deletes that businessPartner to the recieved input (new User)/
 * We then send this new User through the RetailServer API so that the database
 * is updated.
 */
export const deleteOrganizationUserDataAction =  createObservableDataAction<BusinessPartnerUser>({
    id: '@msdyn365-commerce-modules/retail-actions/delete-organization-user',
    action: <IAction<BusinessPartnerUser>>deleteOrganizationUser,
    input: <(args: ICreateActionContext) => IActionInput>(<IAny>createDeleteOrganizationUserInput),
});

export default deleteOrganizationUserDataAction;