import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { ProductWarehouseInventoryAvailability } from '@msdyn365-commerce/retail-proxy';
import { getEstimatedAvailabilityAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { ProductAvailableQuantity, ReleasedProductType, ProductType  } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { getSelectedProductIdFromActionInput, getSelectedVariant, IProductInventoryInformation, SelectedVariantInput } from './index';
import { mapProductInventoryInformation } from './utilities/product-inventory-utils';

/**
 * Input class for the getProductAvailabilitiesForSelectedVariant Data Action
 */
export class ProductAvailabilitiesForSelectedVariantInput implements IActionInput {
    public productId: number;
    public channelId: number;

    constructor(productId: number | string, channelId: number) {
        this.productId = typeof productId === 'string' ? +productId : productId;
        this.channelId = channelId;
    }

    public getCacheKey = () => `ProductAvailabilitiesForSelectedVariant`;
    public getCacheObjectType = () => 'ProductAvailabilities';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * createInput method for the getProductAvailabilitiesForSelectedVariant data action.
 * @param inputData The input data passed to the createInput method
 */
export const createProductAvailabilitiesForSelectedVariantInput = (
    inputData: ICreateActionContext<IGeneric<IAny>>
): ProductAvailabilitiesForSelectedVariantInput => {
    const productId = getSelectedProductIdFromActionInput(inputData);

    if (productId) {
        return new ProductAvailabilitiesForSelectedVariantInput(+productId, +inputData.requestContext.apiSettings.channelId);
    } else {
        throw new Error('Unable to create ProductAvailabilitiesForSelectedVariantInput, no productId found on module config or query');
    }
};

/**
 * The action method for the getProductAvailabilitiesForSelectedVariant data action
 */
export async function getProductAvailabilitiesForSelectedVariantAction(
    input: ProductAvailabilitiesForSelectedVariantInput,
    ctx: IActionContext
): Promise<IProductInventoryInformation[] | undefined> {
    const selectedVariantInput = new SelectedVariantInput(input.productId, input.channelId);
    // tslint:disable-next-line:prefer-type-cast
    const PRODUCTASSERVICE = 2 as ReleasedProductType.Service;
    return getSelectedVariant(selectedVariantInput, ctx)
        .then(productResult => {
            // don't get availability if it is service item
            if (productResult && productResult.ItemTypeValue !== PRODUCTASSERVICE && productResult.ProductTypeValue !== ProductType.Master) {
                return getEstimatedAvailabilityAsync({ callerContext: ctx }, { ProductIds: [productResult.RecordId], DefaultWarehouseOnly: true}).then(response => {
                    if(response && response.ProductWarehouseInventoryAvailabilities && response.ProductWarehouseInventoryAvailabilities.length) {
                        return mapProductInventoryInformation(ctx, response.ProductWarehouseInventoryAvailabilities);
                    }
                    return undefined;
                });
            }

            return undefined;
        })
        .catch((error: Error) => {
            ctx.trace(error.message);
            ctx.telemetry.exception(error);
            ctx.telemetry.debug(`[getPriceForSelectedVariantAction]Error executing action`);
            throw new Error('[getPriceForSelectedVariantAction]Error executing action');
        });
}

/**
 * The function that maps a ProductWareHouse object into a ProductAvailabilityQuantity
 */
export function mergeProductWarehouseToProductAvailabities(productsWarehouseInventory: ProductWarehouseInventoryAvailability[]): ProductAvailableQuantity[] {
    const productAvailable: ProductAvailableQuantity[] = [];
    if(productsWarehouseInventory && productsWarehouseInventory.length > 0) {
        for(const product of productsWarehouseInventory) {
            if(product.TotalAvailable !== undefined && product.ProductId !== undefined) {
                productAvailable.push({ProductId: product.ProductId, AvailableQuantity: product.TotalAvailable});
            }
        }
    }
    return productAvailable;
}


/**
 * The complete getProductAvailabilitiesForSelectedVariant data action
 * Get the currently selected variant via the getSelectedVariant data action, and
 * then gets the availabilities for the variant via the getProductAvailabilities RetailServer API
 */
export const getProductAvailabilitiesForSelectedVariantActionDataAction = createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-product-availabilities-for-selected-variant',
    action: <IAction<IProductInventoryInformation[] | null>>getProductAvailabilitiesForSelectedVariantAction,
    input: createProductAvailabilitiesForSelectedVariantInput
});

export default getProductAvailabilitiesForSelectedVariantActionDataAction;
