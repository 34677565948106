import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { FeatureState } from '@msdyn365-commerce/retail-proxy';
import { getFeatureStatesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/StoreOperationsDataActions.g';

/**
 *  Input class for the getFeatureState data action.
 *  The life time of the data action is 'application'.
 */
export class FeatureStateInput implements IActionInput {
    // Any additional feature names that needs to be checked in the code for enablement needs to be appended here:
    public readonly featureNames: string[] = [
        'Dynamics.AX.Application.RetailB2BEcommerceFeature',
        'Dynamics.AX.Application.RetailDefaultOrderQuantityLimitsFeature',
        'Dynamics.AX.Application.RetailMultiplePickupDeliveryModeFeature'
    ];

    public getCacheKey = () => 'FeatureState';
    public getCacheObjectType = () => 'FeatureState';
    public dataCacheType = (): CacheType => 'application';
}

/**
 * createInput method for the getFeatureState method
 * @param inputData The input data passed to the createInput method
 */
export const createGetFeatureStateInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new FeatureStateInput();
};

/**
 * The action method for the getFeatureState data action
 * @param input The action input
 * @param ctx The action context
 */
export async function getFeatureStateAction(input: FeatureStateInput, ctx: IActionContext): Promise<FeatureState[]> {
    // tslint:disable-next-line:no-unnecessary-local-variable
    const featureState = await getFeatureStatesAsync({ callerContext: ctx }, input.featureNames);
    return featureState;
}

/**
 * The getFeatureState data action
 * Gets the feature state via the read RetailServer API
 * Returns address information associated with the retrieved customer
 */
export const getFeatureStateActionDataAction =  createObservableDataAction<FeatureState[]>({
    id: '@msdyn365-commerce-modules/retail-actions/get-feature-state',
    action: <IAction<FeatureState[]>>getFeatureStateAction,
    input: createGetFeatureStateInput
});

export default getFeatureStateActionDataAction;