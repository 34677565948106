import {
    CacheType, createObservableDataAction, IAction, IActionContext, IActionInput,
    ICommerceApiSettings, ICreateActionContext
} from '@msdyn365-commerce/core';
import { CustomerBalances } from '@msdyn365-commerce/retail-proxy';
import {
    getCustomerBalanceAsync
} from '@msdyn365-commerce/retail-proxy/dist/DataActions/StoreOperationsDataActions.g';

import getCustomer, { GetCustomerInput } from './get-customer';
import { buildCacheKey } from './index';

/**
 *  Input class for the GetCustomerBalance data action
 */
export class GetCustomerBalanceInput implements IActionInput {
    public customerAccountNumber?: string;
    public apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings, customerAccountNumber?: string) {
        this.customerAccountNumber = customerAccountNumber;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`CustomerBalance-${this.customerAccountNumber}`, this.apiSettings);
    public getCacheObjectType = () => 'GetCustomerBalance';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * createInput method for the GetCustomerBalance method
 * @param inputData The input data passed to the createInput method
 */

export const createGetCustomerBalanceInput = (inputData: ICreateActionContext): GetCustomerBalanceInput => {
    const { requestContext } = inputData;
    if (!requestContext.user.isAuthenticated) {
        throw new Error('Unable to create get customer balance input. User is not authenticated.');
    }

    return new GetCustomerBalanceInput(requestContext.apiSettings);
};

/**
 * The action method for the GetCustomerBalance data action
 * @param input The action input
 * @param ctx The action context
 */
export async function getCustomerBalanceAction(input: GetCustomerBalanceInput, ctx: IActionContext): Promise<CustomerBalances> {
    const customerInput = new GetCustomerInput(input.apiSettings, input.customerAccountNumber);
    const customer = await getCustomer(customerInput, ctx);

    if (!customer) {
        ctx.telemetry.error('Not able to get customer', new Error('Not able to get customer'));
    }

    return getCustomerBalanceAsync(
        {
            callerContext: ctx,
            queryResultSettings: {}
        },
        customer.AccountNumber,
        customer.InvoiceAccount!
    );
}

/**
 * The GetCustomerBalance data action
 * Returns the customer's balance
 */
export const getCustomerBalanceActionDataAction = createObservableDataAction<CustomerBalances>({
    id: '@msdyn365-commerce-modules/retail-actions/get-customer-balance',
    action: <IAction<CustomerBalances>>getCustomerBalanceAction,
    input: <(args: ICreateActionContext) => IActionInput>createGetCustomerBalanceInput
});

export default getCustomerBalanceActionDataAction;
