import { CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICreateActionContext } from '@msdyn365-commerce/core';
import { updateAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductListsDataActions.g';
import { ProductList } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

/**
 *  Input class for the UpdateOrderTemplate data action.
 */
export class UpdateOrderTemplateHeaderInput implements IActionInput {
    public readonly orderTemplate: ProductList;

    constructor(orderTemplate: ProductList) {
        this.orderTemplate = orderTemplate;
    }

    public getCacheKey = () => 'UpdateOrderTemplateHeaderInput';
    public getCacheObjectType = () => 'UpdateOrderTemplateHeaderInput';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * createInput method for the UpdateOrderTemplateHeader method.
 * @param {ICreateActionContext} inputData The input data passed to the createInput method.
 * @param {ProductList} orderTemplate The template header.
 */
export const updateOrderTemplateHeaderInput = (inputData: ICreateActionContext, orderTemplate: ProductList): UpdateOrderTemplateHeaderInput => {
    const { requestContext } = inputData;
    if (!requestContext.user.isAuthenticated) {
        throw new Error('Unable to update order template. User is not authenticated.');
    }
    return new UpdateOrderTemplateHeaderInput(orderTemplate);
};

/**
 * The action method for the UpdateOrderTemplate data action.
 * @param {UpdateOrderTemplateHeaderInput} input The action input.
 * @param {IActionContext} ctx The action context.
 */
export async function updateOrderTemplateHeaderAction(input: UpdateOrderTemplateHeaderInput, ctx: IActionContext): Promise<ProductList> {
    try {
        const { orderTemplate } = input;
        const updatedProductList = await updateAsync({
            callerContext: ctx
        }, orderTemplate);
        if (updatedProductList) {
            return updatedProductList;
        } else {
            ctx.telemetry.error(`Updated product list ${orderTemplate.Id} came back as null.`);
            throw Error('Updated product list was null.');
        }
    } catch (error) {
        ctx.telemetry.error('Not able to update order template', error);
        throw error;
    }
}

/**
 * The UpdateOrderTemplate Data Action.
 * Returns updated order template.
 */
export const updateOrderTemplateHeaderActionDataAction =  createObservableDataAction<ProductList>({
    id: '@msdyn365-commerce-modules/retail-actions/order-templates/update-order-template-header',
    action: <IAction<ProductList>>updateOrderTemplateHeaderAction,
    input: updateOrderTemplateHeaderInput
});

export default updateOrderTemplateHeaderActionDataAction;