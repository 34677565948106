import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICreateActionContext
} from '@msdyn365-commerce/core';
import { ProductList, ProductListType } from '@msdyn365-commerce/retail-proxy';
import { createProductListAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductListsDataActions.g';

/**
 *  Input class for the CreateOrderTemplate data action.
 */
export class CreateOrderTemplateInput implements IActionInput {
    public readonly templateName: string;

    constructor(templateName: string) {
        this.templateName = templateName;
    }

    public getCacheKey = () => 'CreateOrderTemplateInput';
    public getCacheObjectType = () => 'CreateOrderTemplateInput';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * createInput method for the CreateOrderTemplate method.
 * @param {ICreateActionContext} inputData The input data passed to the createInput method.
 * @param {string} templateName The name of template.
 */
export const createOrderTemplateInput = (inputData: ICreateActionContext, templateName: string): CreateOrderTemplateInput => {
    const { requestContext } = inputData;
    if (!requestContext.user.isAuthenticated) {
        throw new Error('Unable to create order template. User is not authenticated.');
    }
    return new CreateOrderTemplateInput(templateName);
};

/**
 * The action method for the CreateOrderTemplate data action.
 * @param {CreateOrderTemplateInput} input The action input.
 * @param {IActionContext} ctx The action context.
 */
export async function createOrderTemplateAction(input: CreateOrderTemplateInput, ctx: IActionContext): Promise<ProductList> {
    try {
        const orderTemplate: ProductList = {
            Id: '',
            Name: input.templateName,
            ProductListTypeValue: ProductListType.OrderTemplate
        };
        const createdProductList = await createProductListAsync({
            callerContext: ctx
        }, orderTemplate);
        if (createdProductList && createdProductList.Id !== '') {
            return createdProductList;
        } else {
            ctx.telemetry.error('Created product list was null');
            throw Error('Created product list was null');
        }
    } catch (error) {
        ctx.telemetry.error('Not able to create order template', error);
        throw error;
    }
}

/**
 * The CreateOrderTemplate Data Action.
 * Returns created order template.
 */
export const createOrderTemplateActionDataAction = createObservableDataAction<ProductList>({
    id: '@msdyn365-commerce-modules/retail-actions/order-templates/create-order-template',
    action: <IAction<ProductList>>createOrderTemplateAction,
    input: createOrderTemplateInput
});

export default createOrderTemplateActionDataAction;