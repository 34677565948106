import { ProductDimensionFull } from '@msdyn365-commerce/commerce-entities';
import { CacheType, IAction, IActionContext, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { ProductDimension, SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import getSelectedVariant, { SelectedVariantInput } from './get-selected-variant';
import { getSelectedProductIdFromActionInput } from './utilities/utils';

import { getDimensionValuesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';

/**
 * Input class for the getDimensionsForSelectedVariant data action
 */
export class GetDimensionsForSelectedVariantInput implements IActionInput {
    public productId: number;
    public channelId: number;
    public matchingDimensionValues: ProductDimension[];

    constructor(productId: number, channelId: number, matchingDimensionValues?: ProductDimension[]) {
        this.productId = productId;
        this.channelId = channelId;
        this.matchingDimensionValues = matchingDimensionValues || [];
    }

    public getCacheKey = () => `DimensionsForSelectedVariant`;
    public getCacheObjectType = () => 'DimensionsForSelectedVariantInput';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * Create input method for the getDimensionsForSelectedVariant data action
 * @param inputData The input data passed to the createInput method
 */
const createDimensionsForSelectedVariantInput = (inputData: ICreateActionContext<IGeneric<IAny>>): SelectedVariantInput => {
    const productId = getSelectedProductIdFromActionInput(inputData);

    if (productId) {
        return new GetDimensionsForSelectedVariantInput(+productId, +inputData.requestContext.apiSettings.channelId, []);
    } else {
        throw new Error('Unable to create DimensionsForSelectedVariantInput, no productId found on module config or query');
    }
};

/**
 * Action Method for the getDimensionsForSelectedVariant Data Action
 */
async function getDimensionsForSelectedVariantAction(
    input: GetDimensionsForSelectedVariantInput,
    ctx: IActionContext
): Promise<ProductDimensionFull[]> {
    const selectedVariantInput = new SelectedVariantInput(input.productId, input.channelId, input.matchingDimensionValues);

    const activeProduct: SimpleProduct | null = await getSelectedVariant(selectedVariantInput, ctx);

    if (activeProduct && activeProduct.Dimensions && activeProduct.Dimensions.length > 0) {
        const results: ProductDimensionFull[] = [];

        for (const dimension of activeProduct.Dimensions) {
            const dimensionValues = await getDimensionValuesAsync(
                { callerContext: ctx, queryResultSettings: {} },
                activeProduct.MasterProductId ? activeProduct.MasterProductId : activeProduct.RecordId,
                input.channelId,
                dimension.DimensionTypeValue,
                input.matchingDimensionValues.filter(value => value.DimensionTypeValue !== dimension.DimensionTypeValue),
                // @ts-ignore: KitVariantResolution context should be nullable
                null
            );

            const fullDimension: ProductDimensionFull = {
                ...dimension,
                DimensionValues: dimensionValues,
            };

            results.push(fullDimension);
        }

        return results;
    }

    return [];
}

/**
 * The GetDimensionsForSelectedVariantDataAction
 * Get the currently selected Variant via the getSelectedVariant data action, and the gets the
 * available product dimensions for the variant via the getDimensionValues RetailServer API
 */
export const getDimensionsForSelectedVariantActionDataAction = createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-dimensions/for-selected-variant',
    action: <IAction<ProductDimensionFull[]>>getDimensionsForSelectedVariantAction,
    input: createDimensionsForSelectedVariantInput
});

export default getDimensionsForSelectedVariantActionDataAction;
