import { CacheType, IAction, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, ICreateActionContext } from '@msdyn365-commerce/core';
import { InvoiceDetailsSearchCriteria, SalesInvoice, SalesOrdersDataActions } from '@msdyn365-commerce/retail-proxy';

/**
 * Input class for get invoice details data action
 */
export class GetInvoiceDetailsInput implements IActionInput {
    public invoiceId: string;

    constructor(invoiceId: string) {
        this.invoiceId = invoiceId;
    }

    public getCacheKey = () => `GetInvoiceDetails|${this.invoiceId}`;
    public getCacheObjectType = () => 'SalesInvoice';
    public dataCacheType = (): CacheType => 'request';
}

export const CreateGetInvoiceDetailsInput = (inputData: ICreateActionContext): IActionInput => {
    const { requestContext } = inputData;

    // Check if the user is authenticated.
    if (!requestContext.user.isAuthenticated) {
        throw new Error('Unable to get invoice details as User is not logged in.');
    }
    if (!inputData?.requestContext?.query?.invoiceId) {
        throw new Error('Unable to get invoice details as no valid invoice id found.');
    }

    return new GetInvoiceDetailsInput(inputData.requestContext.query.invoiceId);
};

export async function getInvoiceDetails(input: GetInvoiceDetailsInput, context: IActionContext): Promise<SalesInvoice> {
    if (!context?.requestContext?.user?.isAuthenticated) {
        throw new Error('Unable to get details for the invoice. User is not authenticated.');
    }

    if (!input.invoiceId) {
        throw new Error('Unable to get invoice details as no valid invoice id found.');
    }

    const invoiceId = input.invoiceId;

    const searchCriteria: InvoiceDetailsSearchCriteria = {
        InvoiceId: invoiceId
    };

    return SalesOrdersDataActions.getInvoiceDetailsAsync({ callerContext: context }, searchCriteria);
}

export const getInvoiceDetailsDataAction = createObservableDataAction({
    id: 'get-invoice-details',
    action: <IAction<SalesInvoice[]>>getInvoiceDetails,
    input: CreateGetInvoiceDetailsInput
});

export default getInvoiceDetailsDataAction;
