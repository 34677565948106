import { CacheType, IAction, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, ICreateActionContext } from '@msdyn365-commerce/core';
import { InvoicePaidStatus, InvoiceSearchCriteria, InvoiceType, OrderInvoice, SalesOrdersDataActions } from '@msdyn365-commerce/retail-proxy';

/**
 * Input class for get invoice data action
 */
export class GetInvoiceInput implements IActionInput {
    public invoiceId: string;

    constructor(invoiceId: string) {
        this.invoiceId = invoiceId;
    }

    public getCacheKey = () => `GetInvoice|${this.invoiceId}`;
    public getCacheObjectType = () => 'OrderInvoice';
    public dataCacheType = (): CacheType => 'request';
}

export const CreateGetInvoiceInput = (inputData: ICreateActionContext): IActionInput => {
    const { requestContext } = inputData;

    // Check if the user is authenticated.
    if (!requestContext.user.isAuthenticated) {
        throw new Error('Unable to get invoice as User is not logged in.');
    }
    if (!inputData?.requestContext?.query?.invoiceId) {
        throw new Error('Unable to get invoice as no valid invoice id found.');
    }

    return new GetInvoiceInput(inputData.requestContext.query.invoiceId);
};

export async function getInvoice(input: GetInvoiceInput, context: IActionContext): Promise<OrderInvoice> {
    if (!context?.requestContext?.user?.isAuthenticated) {
        throw new Error('Unable to get invoice. User is not authenticated.');
    }

    if (!input.invoiceId) {
        throw new Error('Unable to get invoice as no valid invoice id found.');
    }

    const invoiceId = input.invoiceId;

    const searchCriteria: InvoiceSearchCriteria = {
        InvoiceIds: [invoiceId],
        InvoiceTypeValues: [InvoiceType.SalesOrderInvoice],
        InvoicePaidStatusValues: [InvoicePaidStatus.None]
    };

    const invoices = await SalesOrdersDataActions.getInvoicesAsync({ callerContext: context }, searchCriteria);
    if (!invoices || invoices.length !== 1) {
        throw new Error('Unable to get invoice from the server.');
    }

    return invoices[0];
}

export default createObservableDataAction({
    id: 'get-invoice',
    action: <IAction<OrderInvoice>>getInvoice,
    input: CreateGetInvoiceInput
});
