import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { BusinessPartnerUser } from '@msdyn365-commerce/retail-proxy';
import { updateAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/BusinessPartnerUsersDataActions.g';
/**
 * Input class for edit organization user data action
 */
export class EditOrganizationUserInput implements IActionInput {
    public user: BusinessPartnerUser;

    constructor(newUser: BusinessPartnerUser) {
        this.user = newUser;
    }

    public getCacheKey = () => `EditOrganizationUser`;
    public getCacheObjectType = () => 'EditOrganizationUser';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * Input class for edit organization user data action
 */
export const createEditOrganizationUserInput = (inputData: ICreateActionContext<IGeneric<IAny>>, newUser: BusinessPartnerUser): IActionInput => {
    const { requestContext } = inputData;
    
    // Check if the user is authenticated.
    if (!requestContext.user.isAuthenticated) {
        throw new Error('User is not logged in.');
    }

    return new EditOrganizationUserInput(newUser);
};

export async function editOrganizationUser(input: EditOrganizationUserInput, ctx: IActionContext): Promise<BusinessPartnerUser> {
    const { user } = input;

    return updateAsync({ callerContext: ctx}, user)
        .then(updatedUser => {
            return updatedUser;
        })
        .catch(() => {
            ctx.telemetry.debug('[Edit-organization-user] Unable to update BusinessPartnerUser account');
            throw new Error('[Edit-organization-user] Unable to update BusinessPartnerUser account');
        });
}

/**
 * The EditOrganizationUser data action
 * Takes in an instance of a BusinessPartnerUser and updates the
 * same user with the new information.
 */
export const editOrganizationUserDataAction =  createObservableDataAction<BusinessPartnerUser>({
    id: '@msdyn365-commerce-modules/retail-actions/Edit-organization-user',
    action: <IAction<BusinessPartnerUser>>editOrganizationUser,
    input: <(args: ICreateActionContext) => IActionInput>(<IAny>createEditOrganizationUserInput),
});

export default editOrganizationUserDataAction;