import { getUrlSync, IActionContext } from '@msdyn365-commerce/core';

/**
 * Syncronously creates an invoice detail page url
 * @param invoiceId Invoice Id
 */
export function getInvoiceDetailsPageUrlSync(invoiceId: String, ctx: IActionContext): string {
    let url = getUrlSync('invoice-details', ctx) || '';
    url = url.includes('?') ? url.substr(0, url.indexOf('?')) : url;
    return `${url}?invoiceId=${invoiceId}`;
}