import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IHTTPRequestOptions,
    IHTTPResponse,
    sendCommerceRequest
} from '@msdyn365-commerce/core';
import { Address, CartTenderLine, SalesOrder } from '@msdyn365-commerce/retail-proxy';

/**
 * Check Out action Input
 */
export class CheckOutInput implements IActionInput {
    public cartId: string;
    public cartVersion: number;
    public receiptEmail?: string;
    public cartTenderLines?: CartTenderLine[];
    public billingAddress?: Address;

    constructor(cartId: string, cartVersion: number, receiptEmail?: string, cartTenderLines?: CartTenderLine[], billingAddress?: Address) {
        this.cartId = cartId;
        this.cartVersion = cartVersion;
        this.receiptEmail = receiptEmail;
        this.cartTenderLines = cartTenderLines;
        this.billingAddress = billingAddress;
    }

    public getCacheKey = () => `${this.cartId}-${this.cartVersion}`;
    public getCacheObjectType = () => 'CheckOut';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * Calls the Retail API to check out and returns sales order
 */
export async function checkOutAction(input: CheckOutInput, ctx: IActionContext): Promise<SalesOrder> {
    const { apiSettings } = ctx.requestContext;
    const requestUrl = `${apiSettings.baseUrl}Commerce/Carts('${input.cartId}')/Checkout?api-version=7.3`;
    const requestOptions: IHTTPRequestOptions = {
        headers: {
            oun: apiSettings.oun,
            'Content-Type': 'application/json'
        }
    };

    if (requestOptions.headers && ctx.requestContext.user.token) {
        requestOptions.headers.Authorization = `id_token ${ctx.requestContext.user.token}`;
    }

    if (input.cartTenderLines && input.cartTenderLines.length) {
        input.cartTenderLines = input.cartTenderLines.map((cartTenderLineItem: CartTenderLine) => {
            // @ts-ignore
            // tslint:disable-next-line:prefer-type-cast
            cartTenderLineItem['@odata.type'] = '#Microsoft.Dynamics.Commerce.Runtime.DataModel.CartTenderLine';
            // @ts-ignore
            // tslint:disable-next-line:prefer-type-cast
            cartTenderLineItem['Amount@odata.type'] = '#Decimal';

            if (cartTenderLineItem.TokenizedPaymentCard) {
                // @ts-ignore
                // tslint:disable-next-line:prefer-type-cast
                cartTenderLineItem.TokenizedPaymentCard['@odata.type'] =
                    '#Microsoft.Dynamics.Commerce.Runtime.DataModel.TokenizedPaymentCard';

                // Force the House prop to be set, so that call succeeds
                // @ts-ignore: House prop not yet typed
                if (!cartTenderLineItem.TokenizedPaymentCard.House) {
                    // @ts-ignore: House prop not yet typed
                    cartTenderLineItem.TokenizedPaymentCard.House = 'N/A';
                }

                if (cartTenderLineItem.TokenizedPaymentCard.CardTokenInfo) {
                    // @ts-ignore
                    // tslint:disable-next-line:prefer-type-cast
                    cartTenderLineItem.TokenizedPaymentCard.CardTokenInfo['@odata.type'] =
                        '#Microsoft.Dynamics.Commerce.Runtime.DataModel.CardTokenInfo';
                }

                if (input.billingAddress) {
                    const { Phone, ThreeLetterISORegionName = '', Street, City, State, ZipCode } = input.billingAddress;
                    cartTenderLineItem.TokenizedPaymentCard = {
                        ...cartTenderLineItem.TokenizedPaymentCard,
                        Phone,
                        Country: ThreeLetterISORegionName.toUpperCase(),
                        Address1: Street,
                        City,
                        State,
                        Zip: ZipCode
                    };
                }
            }

            return cartTenderLineItem;
        });
    }

    const requestBody = {
        receiptEmail: input.receiptEmail,
        cartVersion: input.cartVersion || null,
        ...(input.cartTenderLines && { cartTenderLines: input.cartTenderLines })
    };

    // Perform check-out operation
    return sendCommerceRequest<SalesOrder>(requestUrl, 'post', requestBody, requestOptions).then((response: IHTTPResponse<SalesOrder>) => {
        if (response.status >= 200 && response.status < 300 && response.data) {
            return response.data;
        } else if (response.data) {
            throw response.data;
        }
        throw new Error('[checkOutAction] Invalid response recieved from RetailServer');
    });
}

export const checkOutActionDataAction =  createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/checkout',
    action: <IAction<SalesOrder>>checkOutAction
});
export default checkOutActionDataAction;
