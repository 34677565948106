import {
    CacheType, createObservableDataAction, IAction, IActionContext, IActionInput,
    ICommerceApiSettings
} from '@msdyn365-commerce/core';
import { Customer, CustomerAttribute } from '@msdyn365-commerce/retail-proxy';
import {
    readAsync, updateAsync
} from '@msdyn365-commerce/retail-proxy/dist/DataActions/CustomersDataActions.g';

import { GetCustomerInput } from './get-customer';
import { buildCacheKey } from './index';

export interface IUpdateCustomerAttributesInput extends IActionInput {
    phone?: string,
    vatNumber?: string;
    customerAttributes: CustomerAttribute[];
    userAccountNumber: string;
    apiSettings: ICommerceApiSettings;
}

/**
 *  Input class for the updateCustomerAttributes
 */
export class updateCustomerAttributesInput implements IUpdateCustomerAttributesInput {
    public userAccountNumber: string;
    public customerAttributes: CustomerAttribute[];
    public apiSettings: ICommerceApiSettings;
    public phone?: string;
    public vatNumber?: string;

    constructor(userAccountNumber: string, customerAttributes: CustomerAttribute[], apiSettings: ICommerceApiSettings, phone?: string, vatNumber?: string) {
        this.userAccountNumber = userAccountNumber;
        this.apiSettings = apiSettings;
        this.customerAttributes = customerAttributes;
        this.phone = phone;
        this.vatNumber = vatNumber;
    }

    public getCacheKey = (): string => buildCacheKey(`${this.userAccountNumber}`, this.apiSettings);
    public getCacheObjectType = () => 'Customer';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * The action method for the updateCustomerAttributes data action
 * @param input The action input
 * @param ctx The action context
 */
export async function updateCustomerAttributesAction(input: IUpdateCustomerAttributesInput, ctx: IActionContext): Promise<Customer> {
    const { customerAttributes, phone, vatNumber, userAccountNumber } = input;

    const customer = await readAsync({ callerContext: ctx }, userAccountNumber);

    if (!customer) {
        ctx.telemetry.debug('[update-customer-attributes] Unable to find customer');
        throw new Error('Unable to find customer');
    }

    const newCustomer = { ...customer };
    newCustomer.Phone = phone;
    newCustomer.VatNumber = vatNumber;
    newCustomer.Attributes = customerAttributes;

    const updatedCustomer = await updateAsync({ callerContext: ctx }, newCustomer);

    if (!updatedCustomer) {
        ctx.telemetry.debug('[update-customer-attributes] Unable to update customer attributes');
        throw new Error('Unable to update customer attributes');
    }

    ctx.update(new GetCustomerInput(ctx.requestContext.apiSettings), updatedCustomer);

    return updatedCustomer;
}

/**
 * The updateCustomerAttributes data action
 * Returns the updated customer
 */
export const updateCustomerAttributesActionDataAction =  createObservableDataAction<Customer>({
    id: '@msdyn365-commerce-modules/retail-actions/update-customer-attributes',
    action: <IAction<Customer>>updateCustomerAttributesAction
});

export default updateCustomerAttributesActionDataAction;