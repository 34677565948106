import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { AsyncResult, MediaLocation, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { getMediaLocationsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';

import {
    generateImageUrl,
    getSelectedProductIdFromActionInput,
    getSelectedVariant,
    SelectedVariantInput
} from './';

/**
 * The action input class for the getMediaLocationsForSelectedVariant data action
 */
export class MediaLocationsForSelectedVariantInput implements IActionInput {
    public productId: number;
    public channelId: number;
    public catalogId: number;
    public selectedProduct: SimpleProduct | undefined;

    constructor(productId: number, channelId: number, selectedProduct?: SimpleProduct, catalogId?: number) {
        this.productId = productId;
        this.channelId = channelId;
        this.catalogId = catalogId || 0;
        this.selectedProduct = selectedProduct;
    }

    public getCacheKey = () => `MediaLocationsForSelectedVariant`;
    public getCacheObjectType = () => 'MediaLocations';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * The createInput method for the getMediaLocationsForSelectedVariant data action
 * @param inputData The input data passed to the createInput method
 */
export const createMediaLocationsForSelectedVariantInput = (
    inputData: ICreateActionContext<IGeneric<IAny>>
): MediaLocationsForSelectedVariantInput => {
    const productId = getSelectedProductIdFromActionInput(inputData);

    if (productId) {
        return new MediaLocationsForSelectedVariantInput(+productId, +inputData.requestContext.apiSettings.channelId);
    } else {
        throw new Error('Unable to create MediaLocationsForSelectedVariantInput, no productId found on module config or query');
    }
};

/**
 * The action method for the getMediaLocationsForSelectedVariant data action
 */
export async function getMediaLocationsForSelectedVariantAction(
    input: MediaLocationsForSelectedVariantInput,
    ctx: IActionContext
): Promise<MediaLocation[]> {
    return AsyncResult.resolve()
        .then(() => {
            const activeProduct: SimpleProduct | undefined = input.selectedProduct;

            if (!activeProduct) {
                const selectedVariantInput = new SelectedVariantInput(input.productId, input.channelId);

                // @ts-ignore: Typing issues that will auto-resolve when SDK 1.4 publishes
                return <AsyncResult<SimpleProduct>>getSelectedVariant(selectedVariantInput, ctx);
            }

            return activeProduct;
        })
        .then(productResult => {
            const activeProduct: SimpleProduct | undefined = <SimpleProduct | undefined>productResult;

            if (activeProduct) {
                return getMediaLocationsAsync({ callerContext: ctx, queryResultSettings: {} }, activeProduct.RecordId, input.channelId, input.catalogId).then(response => {
                    return response.map(
                        (mediaLocation: MediaLocation): MediaLocation => {
                            mediaLocation.Uri = generateImageUrl(mediaLocation.Uri, ctx.requestContext.apiSettings);
                            mediaLocation.AltText = mediaLocation.AltText ? mediaLocation.AltText : activeProduct.Name;
                            return mediaLocation;
                        }
                    );
                });
            }

            return [];
        })
        .catch(error => {
            ctx.trace(error);
            ctx.telemetry.error(error.message);
            ctx.telemetry.debug(`[getMediaLocationsForSelectedVariantAction]Error executing action`);
            throw new Error('[getMediaLocationsForSelectedVariantAction]Error executing action');
        });
}

/**
 * The getMediaLocationsForSelectedVariant data action
 * Gets the currently selected variant for the page via the getSelectedVariant data action,
 * and then gets the media location information for the variant via the MediaLocations RetailServer API
 */
export const getMediaLocationsForSelectedVariantActionDataAction = createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-media-locations-for-selected-variant',
    action: <IAction<MediaLocation[] | null>>getMediaLocationsForSelectedVariantAction,
    input: createMediaLocationsForSelectedVariantInput
});

export default getMediaLocationsForSelectedVariantActionDataAction;