import { buildCacheKey, generateImageUrl, getProductUrlSync, getSelectedProductIdFromActionInput, } from '@msdyn365-commerce-modules/retail-actions';
import { CacheType, createObservableDataAction, IAction, IActionContext,IActionInput, ICommerceApiSettings, ICreateActionContext } from '@msdyn365-commerce/core';
import { getAttributeValuesAsync, getByIdAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { IPageSummaryData } from '../IPageSummaryData';
import { IProductPageSummaryConfig } from './product-page-summary.props.autogenerated';

/** Product Page Summary Input */
export class ProductPageSummaryInput implements IActionInput {
    public apiSettings: ICommerceApiSettings;
    public config: IProductPageSummaryConfig;
    public productId: number;

    constructor(config: IProductPageSummaryConfig, apiSettings: ICommerceApiSettings, productId: number) {
        this.config = config || {};
        this.apiSettings = apiSettings;
        this.productId = productId;
    }

    public getCacheObjectType = (): string => 'ProductPageSummary';
    public getCacheKey = (): string => `${buildCacheKey('ProductPageSummary', this.apiSettings)}-${this.config.title}-${this.productId}`;
    public dataCacheType = (): CacheType => 'application';
}

export interface IProductPageSummary extends IPageSummaryData {
    price?: number;
    productId?: number;
    productBrand?: string;
}

const createInput = (args: ICreateActionContext) => {
    const productId = getSelectedProductIdFromActionInput(args);

    if (productId) {
        return new ProductPageSummaryInput(<IProductPageSummaryConfig>args.config, args.requestContext.apiSettings, +productId);
    } else {
        throw new Error('Unable to create ProductPageSummaryAction input, no productId found on module config or query');
    }

};

// Checks product attributes for the presence of brand attribute to extract the brand name for product metadata
const getProductBrand = async (context: IActionContext, recordId: number, channelId: number, catalogId: number): Promise<string | undefined> => {
    const productAttributes = await getAttributeValuesAsync({ callerContext: context, queryResultSettings: {} }, recordId, channelId, catalogId);
    for (let i = 0; i < productAttributes.length; i++) {
        if (productAttributes[i].Name === 'Brand') {
            return productAttributes[i].TextValue;
        }
    }
    return;
};

const action = async (input: ProductPageSummaryInput, context: IActionContext): Promise<IProductPageSummary> => {
    const { config, productId, apiSettings } = input;
    let simpleProductData;
    try {
        simpleProductData = await getByIdAsync({ callerContext: context }, productId, apiSettings.channelId);
    } catch (e) {
        // Do nothing, if there's an error we fall back to values defined from config
    }
    if (simpleProductData) {
        let productUrl: string | undefined;
        try {
            productUrl = getProductUrlSync(simpleProductData, context);
            const canonicalDomain = context.requestContext.canonicalDomain;
            if (productUrl && canonicalDomain) {
                productUrl = `https://${canonicalDomain}${productUrl}`;
            } else {
                productUrl = undefined;
            }
        } catch (e) {
            productUrl = undefined;
        }
        let productBrand: string | undefined;
        try {
            productBrand = await getProductBrand(context, productId, apiSettings.channelId, apiSettings.catalogId);
        } catch (e) {
            productBrand = undefined;
        }
        return {
            title: simpleProductData.Name,
            description: simpleProductData.Description,
            sharingImageUrl: generateImageUrl(simpleProductData.PrimaryImageUrl, apiSettings),
            canonicalUrl: productUrl,
            faviconUrl: config && config.faviconUrl,
            productId: simpleProductData.RecordId,
            price: simpleProductData.AdjustedPrice,
            productBrand: productBrand
        };
    // If the action fails fallback to values defined from config
    } else if (config) {
        return {
            title: config.title,
            description: config.description,
            sharingImageUrl: config.sharingImage && config.sharingImage.src,
            faviconUrl: config && config.faviconUrl
        };
    } else {
        return {};
    }
};

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/page-summary/product-page-summary',
    action: <IAction<IProductPageSummary>>action,
    input: createInput
});