import { CategoryHierarchy } from '@msdyn365-commerce/commerce-entities';
import { createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric, IRequestContext } from '@msdyn365-commerce/core';
import getCategoriesHierarchy, { CategoriesInput } from './get-categories-hierarchy';

/**
 * Action Input Class for get-current-category-hierarchy
 */
export class CurrentCategoryHierarchyInput extends CategoriesInput {
    public activeCategoryId: number;

    constructor(context: IRequestContext, mappedToHierarchy: boolean, maxItems?: number, activeCategoryId?: number) {
        super(context, mappedToHierarchy, maxItems);
        this.activeCategoryId = activeCategoryId || 0;
    }

    // @ts-ignore: Overriding cache object type
    public getCacheObjectType = () => 'CurrentCategoryHierarchy';
    public getCacheKey = () => `${this.activeCategoryId}-${this.channelId}`;
}

/**
 * Creates the input required for the data action
 */
export const createCategoriesHierarchyInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    const topItems = inputData.config && inputData.config.topCategories && parseInt(inputData.config.topCategories, 10);
    const activeCategoryId =
        (inputData.requestContext.urlTokens && inputData.requestContext.urlTokens.pageType === 'Category' && inputData.requestContext.urlTokens.itemId) ||
        (inputData.requestContext.query && inputData.requestContext.query.categoryId) ||
        0;

    return new CurrentCategoryHierarchyInput(inputData.requestContext, true, topItems, +activeCategoryId);
};

/**
 * Get the hierarchy of categories that are currently active as a parent -> child array.
 * Return will be as follows: [ParentCategory, Child, SubChild, SubSubChild]
 */
export async function getCurrentCategoryHierarchyAction(input: CurrentCategoryHierarchyInput, ctx: IActionContext): Promise<CategoryHierarchy[]> {

    const fullCategoryHierarchy = await getCategoriesHierarchy(
        new CategoriesInput(
            ctx.requestContext,
            true,
            input.maxItems),
        ctx);
    const categoryArray: CategoryHierarchy[] = [];

    const findCategory = (category: CategoryHierarchy) => {
        if(category.RecordId === input.activeCategoryId) {
            categoryArray.push(category);
            return true;
        } else if (category.Children && category.Children.length) {
            category.Children.forEach((childCategory) => {
                if(findCategory(childCategory)) {
                    categoryArray.push(category);
                }
            });
        } else {
            return false;
        }
    };

    fullCategoryHierarchy.forEach((categoryHierarchy: CategoryHierarchy) => {
        findCategory(categoryHierarchy);
    });

    return categoryArray.reverse();
}

export const getCurrentCategoryHierarchyActionDataAction = createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-current-category-hierarchy',
    action: <IAction<CategoryHierarchy[]>>getCurrentCategoryHierarchyAction,
    input: createCategoriesHierarchyInput
});

export default getCurrentCategoryHierarchyActionDataAction;
