import { CacheType, getCategoriesUrlSync, IAction, IActionInput, ICommerceApiSettings, IRequestContext } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { ICategoryPath, ICategoryUrl } from '@msdyn365-commerce/core-internal/dist/types/interfaces/ICategoryPathInterfaces';
import { getCategoryPathsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { CategoryPathLookup} from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { buildCacheKey, getSelectedProductIdFromActionInput } from './utilities/utils';

/**
 * Category path input
 */
export class GetCategoryPathsInput implements IActionInput {
    public readonly ChannelId: number;
    public readonly CatalogId: number;
    public readonly categoryPathLooksups: CategoryPathLookup[];
    public readonly apiSettings: ICommerceApiSettings;
    private constructedCacheKey: string;

    constructor(context: IRequestContext, categoryPathLooksups: CategoryPathLookup[]) {
        this.ChannelId = context.apiSettings.channelId;
        this.CatalogId = context.apiSettings.catalogId || 0;
        this.categoryPathLooksups = categoryPathLooksups;
        this.apiSettings = context.apiSettings;
        this.constructedCacheKey = '';
        categoryPathLooksups.forEach(categoryPath=> {
                this.constructedCacheKey+=`${categoryPath.ProductId && categoryPath.ProductId.toString()}|`;
            });
        this.constructedCacheKey+=`${this.ChannelId.toString()}|`;
        this.constructedCacheKey+=`${this.CatalogId.toString()}|`;
    }

    public getCacheKey = () => buildCacheKey(this.constructedCacheKey, this.apiSettings);
    public getCacheObjectType = () => 'CategoryPath';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * Creates the input required to make the getCategoryPath retail api call
 */
export const createGetCategoryPathsInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    const productId = getSelectedProductIdFromActionInput(inputData);
    if (productId) {
          return new GetCategoryPathsInput(inputData.requestContext, [{ProductId:+productId}]);
    } else {
        throw new Error('Unable to create SelectedVariantInput, no productId found on module config or query');
    }
};

/**
 * Calls the Retail API and returns the category path for a product
 */
export async function getCategoryPathsAction(input: GetCategoryPathsInput, ctx: IActionContext): Promise<ICategoryUrl[]> {
    const categoryPathResults = await getCategoryPathsAsync({ callerContext: ctx },input.ChannelId, input.CatalogId,input.categoryPathLooksups);
    const categoryPath = categoryPathResults[0].CategoryPath && categoryPathResults[0].CategoryPath[0];
    const categoryUrl = getCategoriesUrlSync(<ICategoryPath>categoryPath, ctx);
    if(categoryUrl) {
        return categoryUrl.slice(1);
    }
    return [];
}

export const getCategoryPathsActionDataAction = createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-category-paths',
    action: <IAction<ICategoryUrl[]>>getCategoryPathsAction,
    input: createGetCategoryPathsInput,
});

export default getCategoryPathsActionDataAction;