import { FullProduct } from '@msdyn365-commerce/commerce-entities';
import { CacheType, IAction, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, IAny, ICommerceApiSettings, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { ProjectionDomain } from '@msdyn365-commerce/retail-proxy';

import { getActivePricesAsync, getProductRatingsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import getSimpleProducts, { ProductInput } from './get-simple-products';
import { buildCacheKey, generateKey, getProductDetailsCriteriaFromActionInput } from './utilities/utils';

import * as semver from 'semver';

/**
 * Full Product Input
 */
export class FullProductInput implements IActionInput {
    public productId: number;
    public channelId: number;
    public ProductDetailsCriteria: ProductDetailsCriteria;

    private apiSettings: ICommerceApiSettings;

    constructor(productId: number | string, apiSettings: ICommerceApiSettings, criteria: ProductDetailsCriteria) {
        this.productId = typeof productId === 'string' ? parseInt(productId, 10) : productId;
        this.ProductDetailsCriteria = criteria;
        this.apiSettings = apiSettings;
        this.channelId = apiSettings.channelId;
    }

    public getCacheKey = () => {
        const { getPrice, getRating } = this.ProductDetailsCriteria;
        return buildCacheKey(generateKey([this.productId, getPrice, getRating]), this.apiSettings);
    };
    public getCacheObjectType = () => 'FullProduct';
    public dataCacheType = (): CacheType => 'application';
}

/**
 * Class to define criteria to get full product like price, ratings etc.
 */
export class ProductDetailsCriteria {
    public getPrice: boolean;
    public getRating: boolean;
    constructor(getPrice?: boolean, getRating?: boolean) {
        this.getPrice = getPrice || false;
        this.getRating = getRating || false;
    }
}

/**
 * Creates the input required to make the retail api call
 */
export const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput[] => {
    let productIds = inputData.config && inputData.config.productIds;
    const productDetailsCriteria = getProductDetailsCriteriaFromActionInput(inputData);
    if (typeof productIds === 'string') {
        productIds = productIds.split(',');
    }
    if (Array.isArray(productIds) && productIds.length) {
        return productIds.map((productId: string) => {
            return new FullProductInput(+productId, inputData.requestContext.apiSettings, productDetailsCriteria);
        });
    }
        return [];
};

/**
 * Calls the Retail API and returns the product based on the passed ProductInput
 */
export async function getFullProductsAction(inputs: FullProductInput[], ctx: IActionContext): Promise<FullProduct[]> {
    if (!Array.isArray(inputs) || !inputs.length) {
        ctx.trace('[getFullProductsAction] Invalid or empty inputs passed.');
        return <FullProduct[]>{};
    }

    const { apiSettings } = ctx.requestContext;
    const productInputs = inputs.map(
        (input: FullProductInput): ProductInput => {
            return new ProductInput(input.productId, apiSettings);
        }
    );

    return getSimpleProducts(productInputs, ctx)
        .then(result => {
            return result.map(product => {
            return { ProductDetails: product };
        });
        })
        .then((productCollection: FullProduct[]) => {
    const validProductIds = new Set(productCollection.map(input => input.ProductDetails && input.ProductDetails.RecordId));

            const ratingsAndPricePromises = [
                _getActivePrices(inputs, validProductIds, productCollection, ctx),
                _getProductRatings(inputs, validProductIds, productCollection, ctx)
            ];
    return Promise.all(ratingsAndPricePromises).then(() => {
        return productCollection;
      });
        })
        .catch(e => {
            ctx.telemetry.exception(e);
            ctx.telemetry.debug(`Unable to get Simple products`);
            return [];
        });
}

async function _getActivePrices(
    inputs: FullProductInput[],
    validProductIds: Set<number>,
    productCollection: FullProduct[],
    ctx: IActionContext
): Promise<void> {
    const projectDomain: ProjectionDomain = { ChannelId: +ctx.requestContext.apiSettings.channelId, CatalogId: 0 };
    const validInputs = <number[]>inputs
        .map(input => {
        if (input.ProductDetailsCriteria.getPrice && validProductIds.has(input.productId)) {
            return input.productId;
        }
        })
        .filter(Boolean);

     return getActivePricesAsync(
            { callerContext: ctx, queryResultSettings: {} },
            projectDomain,
            validInputs,
            new Date(),
            null,
            [],
            true
            // @ts-ignore
        ).then(result => {
            // @ts-ignore
            result.forEach(productPrice => {
                const fullProduct: FullProduct | undefined = productCollection.find(x=> {
                    return x.ProductDetails &&  x.ProductDetails.RecordId === productPrice.ProductId;
                   });
                if (fullProduct) {
                    // If RS Verison < 9.16.0 (aka 10.0.6), customer contextual price won't be
                    // included so instead just use AdjustedPrice
                    if (semver.lt(ctx.requestContext.apiSettings.retailServerProxyVersion, '9.16.0')) {
                        productPrice.CustomerContextualPrice = productPrice.AdjustedPrice;
                    }
                    fullProduct.ProductPrice = productPrice;
                }
            });
        })
        .catch((error: Error) => {
            const telemetry = ctx.telemetry;
            telemetry.debug('[getActivePricesAsync] Unable to get active price.');
            telemetry.exception(error);
        });
 }

async function _getProductRatings(
    inputs: FullProductInput[],
    validProductIds: Set<number>,
    productCollection: FullProduct[],
    ctx: IActionContext
): Promise<void> {
    const validInputs = <number[]>inputs
        .map(input => {
       if (input.ProductDetailsCriteria.getRating && validProductIds.has(input.productId)) {
           return input.productId;
       }
        })
        .filter(Boolean);

    return getProductRatingsAsync({ callerContext: ctx, queryResultSettings: {} }, validInputs)
        .then(result => {
            result.forEach(productRating => {
                const fullProduct: FullProduct | undefined = productCollection.find(x=> {
                    return x.ProductDetails &&  x.ProductDetails.RecordId === productRating.ProductId;
                });
                if (fullProduct) {
                    fullProduct.ProductRating = productRating;
                }
            });
        })
        .catch(err => {
            const telemetry = ctx.telemetry;
            telemetry.debug(`[getProductRatingsAsync] Unable to get product ratings.`);
            telemetry.exception(err);
        });
}

export const getFullProductsActionDataAction = createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-full-products',
    action: <IAction<FullProduct[]>>getFullProductsAction,
    input: createInput,
    isBatched: true
});

export default getFullProductsActionDataAction;