import { CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICreateActionContext } from '@msdyn365-commerce/core';
import { IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { ProductList } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { getOrderTemplates, GetOrderTemplatesInput, QueryResultSettingsProxy } from '..';
import { getPaginatedOrderTemplateLinesAction, GetPaginatedOrderTemplateLinesInput } from '..';
import { OrderTemplate } from '../utilities/order-templates';

/**
 *  Input class for the GetOrderTemplates data action
 */
export class GetFullOrderTemplatesInput implements IActionInput {
    public queryResultSettings: IQueryResultSettings;

    constructor(queryResultSettings: IQueryResultSettings) {
        this.queryResultSettings = queryResultSettings;
    }

    public getCacheKey = () => `GetFullOrderTemplates`;
    public getCacheObjectType = () => 'GetFullOrderTemplates';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * createInput method for the GetOrderTemplate method
 * @param inputData The input data passed to the createInput method.
 */
export const createGetFullOrderTemplatesInput = (inputData: ICreateActionContext): GetFullOrderTemplatesInput => {
    const { requestContext } = inputData;
    if (!requestContext.user.isAuthenticated) {
        throw new Error('Unable to get full order templates. User is not authenticated.');
    }
    const queryResultSettings: IQueryResultSettings = QueryResultSettingsProxy.fromInputData(inputData).QueryResultSettings;
    queryResultSettings.count = true;

    return new GetFullOrderTemplatesInput(queryResultSettings);
};

/**
 * The action method for the GetFullOrderTemplates data action
 * @param input The action input
 * @param ctx The action context
 */
export async function getFullOrderTemplatesAction(input: GetFullOrderTemplatesInput, ctx: IActionContext): Promise<OrderTemplate[]> {
    if (!ctx.requestContext.user.isAuthenticated) {
        throw new Error('Unable to get full order templates. User is not authenticated.');
    }

    const orderTemplates: OrderTemplate[] = [];

    const inputParams = new GetOrderTemplatesInput();
    const productLists: ProductList[] = await getOrderTemplates(inputParams, ctx);

    if (!productLists || productLists.length === 0) {
        return orderTemplates;
    }

    for (let i = 0; i < productLists.length; ++i) {
        const list = productLists[i];
        const inputLines = new GetPaginatedOrderTemplateLinesInput(list.Id, input.queryResultSettings);
        const linesState = await getPaginatedOrderTemplateLinesAction(inputLines, ctx);
        orderTemplates.push(new OrderTemplate(list, linesState.lines, linesState.totalCount));
    }

    ctx.trace(orderTemplates.length.toString());
    return orderTemplates;
}

/**
 * The GetFullOrderTemplates Data Action
 * Returns order templates
 */
export const getFullOrderTemplatesActionDataAction = createObservableDataAction<OrderTemplate[]>({
    id: '@msdyn365-commerce-modules/retail-actions/order-templates/get-full-order-templates',
    action: <IAction<OrderTemplate[]>>getFullOrderTemplatesAction,
    input: createGetFullOrderTemplatesInput
});

export default getFullOrderTemplatesActionDataAction;