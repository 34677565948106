import { IEventSubscriber } from './event-subscriber';

/**
 * Represents a simple event with subscribers. Notifies the subscribers once the event is triggered.
 */
export class Event {
    /**
     * The list of subscribers.
     */
    private _subscribers: IEventSubscriber[];

    /**
     * Initializes the event with a empty subscribers list.
     */
    public constructor() {
        this._subscribers = [];
    }

    /**
     * Subscribes given instance to the event.
     * @param {IEventSubscriber} instance The info about the subscriber.
     */
    public subscribe(instance: IEventSubscriber): void {
        this._subscribers.push(instance);
    }

    /**
     * Removes the subscriber from the list of subscribers watching the event.
     * @param {string} instanceId The unique identifier of the subscriber which was earlier passed in `subscribe` method in `IEventSubscriber`.
     */
    public unsubscribe(instanceId: string): void {
        this._subscribers = this._subscribers.filter(element => element.instanceId !== instanceId);
    }

    /**
     * Clears the list of subscriber removing all instances watching the event.
     */
    public unsubscribeAll(): void {
        this._subscribers = [];
    }

    /**
     * Triggers the event.
     * Notifies the subscribers that the event is triggered by calling `handler` method.
     */
    public trigger(): void {
        this._subscribers.forEach(subscriber => {
            subscriber.handler();
        });
    }
}