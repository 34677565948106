/**
 * Represents a pair of two fields: key and value.
 * Used to initialize a dictionary
 */
export interface IKeyValuePair<Key, Value> {
    readonly key: Key;
    readonly value: Value;
}

/**
 * Represents a dictionary in memory storage.
 */
export class Dictionary<Key extends string, Value> {
    /**
     * Values stored in the dictionary.
     */
    private _values: { [eventName: string]: Value };

    /**
     * The number of values stored in the dictionary (does not include keys).
     */
    private _length: number;

    /**
     * Initializes an empty dictionary or a dictionary with the given key value pairs.
     * @param {IKeyValuePair<Key, Value>[]} entries The key value pairs which should be stored in the dictionary initially.
     */
    public constructor(...entries: IKeyValuePair<Key, Value>[]) {
        this._values = {};
        this._length = 0;
        entries.forEach(keyValuePair => this.setValue(keyValuePair.key, keyValuePair.value));
    }

    /**
     * Removes value from the dictionary for the given key.
     * @param {Key} key The key to retrieve the value.
     * @remark Does nothing in case the key is not present in the dictionary.
     */
    public removeValue(key: Key): void {
        if (!this.hasValue(key)) {
            return;
        }

        --this._length;
        delete this._values[key];
    }

    /**
     * Retrieves a value from the dictionary or returns undefined in case it's not found.
     * @param {Key} key The key to retrieve the value.
     * @returns {Value | undefined} The value stored in the dictionary or undefined in case it's not found.
     */
    public getValue(key: Key): Value | undefined {
        return this._values[key];
    }

    /**
     * Retrieves a value from the dictionary.
     * In case it's not found, adds the default value to the dictionary and returns it.
     * @param {Key} key The key to retrieve the value.
     * @returns {Value} The value stored in the dictionary or the default value in case it's not found.
     */
    public getValueWithDefaultValue(key: Key, defaultValue: Value): Value {
        if (!this.hasValue(key)) {
            this.setValue(key, defaultValue);
        }
        return this.getValue(key)!;
    }

    /**
     * Sets the value to the dictionary for the given key.
     * @remarks In case undefined was passed, removes the value from the dictionary instead.
     * @param {Key} key The key under which the value should be stored.
     * @param {Value} value The value which should be stored in the dictionary.
     */
    public setValue(key: Key, value: Value): void {
        if (value === undefined) {
            this.removeValue(key);
            return;
        }

        if (!this.hasValue(key)) {
            ++this._length;
        }

        this._values[key] = value;
    }

    /**
     * Checks if the dictionary stores some value (except undefined) for the given key.
     * @param {Key} key  The key to retrieve the value.
     * @returns {boolean} True in case the value is present, false otherwise or if it's undefined.
     */
    public hasValue(key: Key): boolean {
        return this._values[key] !== undefined;
    }

    /**
     * Checks if the dictionary is empty.
     * @returns {boolean} True if the dictionary is empty, false otherwise.
     */
    public isEmpty(): boolean {
        return this.length === 0;
    }

    /**
     * Retrieves the number of values stored in the dictionary.
     * @remark Use `isEmpty` to check if the dictionary has any elements.
     * @returns {number} The number of values stored.
     */
    public get length(): number {
        return this._length;
    }

    /**
     * Clears the dictionary by removing all elements from the storage.
     */
    public clear(): void {
        this._values = {};
        this._length = 0;
    }

    /**
     * Returns all values stored in the dictionary.
     * @returns {Value[]} List of values.
     */
    public getValues(): Value[] {
        return this.getKeys().map(key => this._values[key]);
    }

    /**
     * Returns all keys stored in the dictionary.
     * @returns {string[]} List of keys.
     */
    public getKeys(): Key[] {
        return <Key[]>Object.keys(this._values);
    }

    /**
     * Returns all key value pairs stored in the dictionary.
     * @returns {IKeyValuePair<Key, Value>[]} List of key value pairs.
     */
    public getKeyValuePairs(): IKeyValuePair<Key, Value>[] {
        return this.getKeys().map(key => {
            return { key, value: this._values[key] };
        });
    }
}