import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, ICommerceApiSettings, ICreateActionContext } from '@msdyn365-commerce/core';
import { BusinessPartnerUser, Customer } from '@msdyn365-commerce/retail-proxy';
import { getCurrentAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/BusinessPartnerUsersDataActions.g';
import { buildCacheKey } from './utilities/utils';
import getCustomer, { GetCustomerInput } from './get-customer';

/**
 * Input class for get current business partner user data action
 */
export class GetCurrentBusinessPartnerUserInput implements IActionInput {
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
    }
    public getCacheKey = () => buildCacheKey('CurrentBusinessPartnerUser', this.apiSettings);
    public getCacheObjectType = () => 'BusinessPartner';
    public dataCacheType = (): CacheType => 'request';
}

export const createGetCurrentBusinessPartnerUserInput = (inputData: ICreateActionContext): IActionInput => {
    const { requestContext } = inputData;

    // Check if the user is authenticated.
    if (!requestContext.user.isAuthenticated) {
        throw new Error('User is not logged in.');
    }

    return new GetCurrentBusinessPartnerUserInput(requestContext.apiSettings);
};

export async function getCurrentBusinessPartnerUser(input: GetCurrentBusinessPartnerUserInput, ctx: IActionContext): Promise<BusinessPartnerUser | undefined> {
    const customerInput = new GetCustomerInput(ctx.requestContext.apiSettings);
    const customer: Customer = await getCustomer(customerInput, ctx);

    if (customer?.IsB2b) {   
        return getCurrentAsync({ callerContext: ctx });
    } else {
        return undefined;
    }
}

export default createObservableDataAction({
    id: 'get-current-business-partner-user',
    action: <IAction<BusinessPartnerUser | undefined>>getCurrentBusinessPartnerUser,
    input: createGetCurrentBusinessPartnerUserInput
});
