import { IAction } from '@msdyn365-commerce/core';
import { createObservableDataAction, ICreateActionContext } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy/dist';
import { createGetByCustomerInput } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CommerceListsDataActions.g';
import { CommerceList } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { buildQueryResultSettings } from './utilities/utils';

const createWishlistInput = (inputData: ICreateActionContext) => {
    if (
        inputData.requestContext &&
        inputData.requestContext.user &&
        inputData.requestContext.user.isAuthenticated
    ) {
        return createGetByCustomerInput(buildQueryResultSettings(inputData), null);
    }

    throw new Error('[get-wishlist-by-customer-id] Unable to create input for getWishlists');
};

/**
 * Get Org Unit Configuration Data Action
 */
export const retailActionDataAction = createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-wishlist-by-customer-id',
    action: <IAction<CommerceList[]>>retailAction,
    input: createWishlistInput
});

export default retailActionDataAction;
