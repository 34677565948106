import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICommerceApiSettings, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';
import { CartPromotionLine } from '@msdyn365-commerce/retail-proxy';
import { getAvailablePromotionsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { buildCacheKey } from './index';
import {ActiveCartProductsInput, getActiveCartProductsAction} from './index';

/**
 * Input class for promotion options for items in cart
 */
export class PromotionOptionsForCartLineItems implements IActionInput {
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`ActiveCartLineItemsPromotionOptions`, this.apiSettings);
    public getCacheObjectType = () => 'ActiveCartLineItemsPromotionOptions';
    public dataCacheType = (): CacheType => 'none';
}

const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>) => {
    return new PromotionOptionsForCartLineItems(inputData.requestContext.apiSettings);
};

/**
 * Calls the Retail API to get the promotion options for items in the cart
 */
export async function getPromotionOptionsForCartLineItems(input: PromotionOptionsForCartLineItems, ctx: IActionContext): Promise<CartPromotionLine[]> {
    // If no input is provided fail out
    if (!input) {
        throw new Error('[getPromotionOptionsForCartLineItems]No valid Input was provided, failing');
    }
    const cartState = await getCartState(ctx);
    if(cartState.hasInvoiceLine) {
        ctx.trace('[getPromotionOptionsForCartLineItems] cart has invoice line');
        return <CartPromotionLine[]>[];
    }
    const cart = cartState.cart;
    const products = await getActiveCartProductsAction(new ActiveCartProductsInput(), ctx);

    if(!cart || !cart.CartLines || !products || products.length === 0) {
        ctx.trace('[getPromotionOptionsForCartLineItems] Not able to get cart OR channelConfiguration or no products in cart');
        return <CartPromotionLine[]>[];
    }

    const cartLineIds = cart.CartLines.map(c => c.LineId || '');

    return getAvailablePromotionsAsync({ callerContext: ctx, queryResultSettings: {}, bypassCache: 'get' }, cart.Id, cartLineIds || null)
        .then(result => {
            return result;
        }).catch((error: Error) => {
            ctx.trace(error.message);
            ctx.telemetry.exception(error);
            ctx.telemetry.debug(`[getPromotionOptionsForCartLineItems]Error executing action`);
            throw new Error('[getPromotionOptionsForCartLineItems]Error executing action');
        });
}

export const getPromotionOptionsForCartLineItemsDataAction = createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-promotion-options-for-cartlines',
    action: <IAction<CartPromotionLine[]>>getPromotionOptionsForCartLineItems,
    input: createInput
});

export default getPromotionOptionsForCartLineItemsDataAction;