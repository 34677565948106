import { CacheType, IAction, IActionInput, ICommerceApiSettings } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { MediaLocation } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { generateImageUrl, buildCacheKey } from './utilities/utils';
import { getMediaLocationsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
/**
 * Product Input
 */
export class MediaLocationsByIdInput implements IActionInput {
    public productId?: number;
    public channelId: number;
    public catalogId: number;
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings, productId?: number, catalogId?: number) {
        this.productId = productId;
        this.channelId = +apiSettings.channelId;
        this.catalogId = catalogId || 0;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`MediaLocationID-${this.productId && this.productId.toString()}`, this.apiSettings);
    public getCacheObjectType = () => 'MediaLocations';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * Creates the input required to make the retail api call
 */
export const createMediaLocationsByIdInput = (inputData: ICreateActionContext<IGeneric<IAny>>): MediaLocationsByIdInput => {
    const productId = inputData.config && +inputData.config.productId || undefined;
    return new MediaLocationsByIdInput(inputData.requestContext.apiSettings, productId, undefined);
};

/**
 * Calls the Retail API and returns the product based on the passed MediaLocationsByIdInput
 */
export async function getMediaLocationsByIdAction(input: MediaLocationsByIdInput, ctx: IActionContext): Promise<MediaLocation[]> {
    if (input.productId) {
        return getMediaLocationsAsync({ callerContext: ctx, queryResultSettings: {} }, input.productId, input.channelId, input.catalogId).then(response => {
            return response.map(
                (mediaLocation: MediaLocation): MediaLocation => {
                    mediaLocation.Uri = generateImageUrl(mediaLocation.Uri, ctx.requestContext.apiSettings);
                    mediaLocation.AltText = mediaLocation.AltText ? mediaLocation.AltText : '';
                    return mediaLocation;
                }
            );
        });
    }
    return [];
}

export const getMediaLocationsByIdActionDataAction = createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-media-locations-by-id',
    action: <IAction<MediaLocation[] | null>>getMediaLocationsByIdAction,
    input: createMediaLocationsByIdInput
});

export default getMediaLocationsByIdActionDataAction;