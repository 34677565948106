import { IAny, ICommerceApiSettings, ICreateActionContext, IGeneric, IRequestContext } from '@msdyn365-commerce/core';
import { AsyncResult } from '@msdyn365-commerce/retail-proxy';
import {
    CartLine,
    CommerceListLine,
    ProductSearchResult,
    QueryResultSettings,
    SimpleProduct
} from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { ProductDetailsCriteria } from '../get-full-products';
import { parseSearchData } from './input-data-parser';

export const wrapInResolvedAsyncResult = <T = unknown>(input: T | null | undefined): AsyncResult<T> => {
    return <AsyncResult<T>>{
        status: 'SUCCESS',
        result: input,
        metadata: {}
    };
};

export const wrapInRejectedAsyncResult = <T = unknown>(input: T | null | undefined): AsyncResult<T> => {
    return <AsyncResult<T>>{
        status: 'FAILED',
        result: input,
        metadata: {}
    };
};

export const buildQueryResultSettings = (inputData: ICreateActionContext<IGeneric<IAny>, IGeneric<IAny>>): QueryResultSettings => {
    try {
        const searchInputData = parseSearchData(inputData);
        const search = searchInputData.q;
        if (!search) {
            throw new Error('Query string ?q={searchText} is needed for search actions.');
        }
        const top = (searchInputData.maxItems && Number(searchInputData.maxItems)) || searchInputData.top;
        return { Paging: { Top: top, Skip: searchInputData.skip } };
    } catch (e) {
        return {};
    }
};

/**
 * Utility function to extract the active productId in the following priority:
 * First query param (productId), then UrlToken (itemId), then module config
 * @param inputData The Action Input data
 */
export const getSelectedProductIdFromActionInput = (inputData: ICreateActionContext<IGeneric<IAny>>): string | undefined => {
    if (inputData && inputData.requestContext && inputData.requestContext.query && inputData.requestContext.query.productId) {
        return inputData.requestContext.query.productId;
    } else if (inputData && inputData.requestContext && inputData.requestContext.urlTokens && inputData.requestContext.urlTokens.itemId) {
        return inputData.requestContext.urlTokens.itemId;
    } else if (inputData && inputData.config && inputData.config.productId) {
        return inputData.config.productId;
    }
    return undefined;
};

/**
 * Generates a Image URL based on data return from retail server
 * @param imageUrl The image url returned by Retail Server
 * @param ctx The request context
 */
export const generateImageUrl = (imageUrl: string | undefined, apiSettings: ICommerceApiSettings): string | undefined => {
    if (imageUrl) {
        // Images hosted in CMS include schema
        if (imageUrl.startsWith('http')) {
            return imageUrl;
        }

        // Images hosted in Retail Server must be encoded and joined with the base image url
        return apiSettings.baseImageUrl + encodeURIComponent(imageUrl);
    } else {
        // d365Commerce.telemetry.warning(`Unable to generate a proper Image URL for Product: ${product.RecordId}`);
        return undefined;
    }
};

/**
 * Generates a Image URL for a product based on data return from retail server
 * @param product The Product returned by Retail Server
 * @param ctx The request context
 */
export const generateProductImageUrl = (
    product: SimpleProduct | ProductSearchResult,
    apiSettings: ICommerceApiSettings
): string | undefined => {
    return generateImageUrl(product.PrimaryImageUrl, apiSettings);
};

/**
 * Creates a CartLine object from the passed data
 * @param product The product
 * @param quantity The quantity
 * @param catalogId The catalog
 */
export const buildCartLine = (product: SimpleProduct, quantity?: number, catalogId?: number): CartLine => {
    return {
        CatalogId: catalogId || 0,
        Description: product.Description,
        // TODO: Investigate this value and what it represents
        EntryMethodTypeValue: 3,
        ItemId: product.ItemId,
        ProductId: product.RecordId,
        Quantity: quantity || 1,
        TrackingId: '',
        UnitOfMeasureSymbol: product.DefaultUnitOfMeasure
    };
};

/**
 * Creates a CommerceListLine (also know as WishlistLine) object from the passed data
 * @param productId The RecordId of the Product to be added
 * @param customerId The account number of the customer
 * @param wishlistId The Id of the commerce list
 */
export const buildWishlistLine = (productId: number, customerId: string, wishlistId: number): CommerceListLine => {
    return {
        CommerceListId: wishlistId,
        ProductId: productId,
        CustomerId: customerId
    };
};

/**
 * Utility function to prepare the product details criteria before getting full product:
 * @param inputData The Action Input data
 */
export const getProductDetailsCriteriaFromActionInput = (inputData: ICreateActionContext<IGeneric<IAny>>): ProductDetailsCriteria => {
    if (inputData && inputData.config) {
        return {
            getPrice: !inputData.config.hidePrice,
            getRating: !inputData.config.hideRating
        };
    }
    return {
        getPrice: true,
        getRating: true
    };
};

/**
 * Transforms search text into the expected 'search terms' format, expected by refiner APIs.
 * @param searchText free-form text used for searching for products or categories of products
 */
export const ensureSearchTextIsFormedProperly = (searchText: string): string => {
    const prefix = searchText.startsWith(`'`) ? '' : `'`;
    const suffix = searchText.endsWith(`'`) ? '' : `'`;
    return `${prefix}${searchText}${suffix}`;
};

export const ensureSafeSearchText = (searchText: string): string => {
    return searchText.replace(/[^0-9a-zA-Z\s]+/g, '');
};

export const buildCacheKey = (base: string, apiSettings: ICommerceApiSettings, locale?:string): string => {
    return `${base}-chanId:${apiSettings.channelId}-catId:${apiSettings.catalogId}${locale?`-${locale}`:''}`;
};

export const buildCacheKeyWithUrlTokens = (base: string, requestContext: IRequestContext): string => {
    const urlTokens = requestContext.urlTokens;
    const defaultCacheKey = buildCacheKey(base, requestContext.apiSettings, requestContext.locale);
    return urlTokens
        ? `${defaultCacheKey}-${urlTokens.itemId}-${urlTokens.recordId}-${urlTokens.pageType}`
        : defaultCacheKey;
};

/**
 * Generates a key from set of arguments as inputs
 *
 * @param args argument list of pivots to generate key from
 * @param handler handler function for null/undefined values
 */

type TKeyTypes = string | number | boolean | null | undefined;
interface IGenerateKeyOptions {
    separator?: string;
    handler?(input: null | undefined): string;
}
export const generateKey = (args: TKeyTypes[], options?: IGenerateKeyOptions): string => {
    const { handler, separator }: IGenerateKeyOptions = { ...{ separator: '-', handler: undefined }, ...(options || {}) };
    return args
        .map(arg => {
            if (arg === null || arg === undefined) {
                if (handler) {
                    return handler(arg);
                }
            }
            return arg;
        })
        .join(separator);
};

/**
 * Gets the fall back image url for a variant image.
 * @param itemId Item id of a product.
 * @param apiSettings Api setting from request context.
 */
export const getFallbackImageUrl = (itemId: string | undefined, apiSettings: ICommerceApiSettings): string | undefined => {
    if (!itemId) {
        return '';
    }

    const productUrl = `Products/${itemId}_000_001.png`;
    return generateImageUrl(productUrl, apiSettings);
};
