import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { ProductPrice, ProjectionDomain, SimpleProduct, AffiliationLoyaltyTier, CustomerAffiliation } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

import { getActivePricesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { getSelectedProductIdFromActionInput, getSelectedVariant, SelectedVariantInput, getCustomer } from './index';

import * as semver from 'semver';
import { GetCustomerInput } from './get-customer';

/**
 * Input class for the GetPriceForSelectedVariant Data Action
 */
export class PriceForSelectedVariantInput implements IActionInput {
    public productId: number;
    public channelId: number;
    public selectedProduct: SimpleProduct | undefined;
    public customerId?: string;

    constructor(productId: number, channelId: number, selectedProduct?: SimpleProduct, customerId?: string) {
        this.productId = productId;
        this.channelId = channelId;
        this.selectedProduct = selectedProduct;
        this.customerId = customerId || '';
    }

    public getCacheKey = () => `PriceForSelectedVariant`;
    public getCacheObjectType = () => 'Price';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * The createInput method for the GetPriceForSelectedVariantDataAction
 * @param inputData The input data for the createInput method
 */
export const createActivePriceForSelectedVariantInput = (inputData: ICreateActionContext<IGeneric<IAny>>): PriceForSelectedVariantInput => {
    const productId = getSelectedProductIdFromActionInput(inputData);

    if (productId) {
        return new PriceForSelectedVariantInput(
            +productId,
            +inputData.requestContext.apiSettings.channelId,
            undefined);
    } else {
        throw new Error('Unable to create PriceForSelectedVariantInput, no productId found on module config or query');
    }
};

/**
 * The Action Method for the GetPriceForSelectedVariant Data Action
 * Pulls the currently selected variant from the cache using the getSelectedVariant data action, and gets it's current contextual price
 * via the getActivePrice RetailServer API
 */
export async function getPriceForSelectedVariantAction(
    input: PriceForSelectedVariantInput,
    ctx: IActionContext
): Promise<ProductPrice | null> {
    let affliations:AffiliationLoyaltyTier[] = [];
    if(ctx.requestContext && ctx.requestContext.user && ctx.requestContext.user.isAuthenticated)
    {
        const customerInput = new GetCustomerInput(ctx.requestContext.apiSettings);
        const customer = await getCustomer(customerInput, ctx);
        if(customer && customer.CustomerAffiliations)
        {
            customer.CustomerAffiliations.forEach((affliation: CustomerAffiliation) => {
                var aff:AffiliationLoyaltyTier = {AffiliationId:affliation.RetailAffiliationId, CustomerId: customer.AccountNumber}
                affliations.push(aff);
                
            });
        }
    }
    return Promise.resolve()
        // @ts-ignore: Promise vs. ObservablePromise typing conflict
        .then(() => {
            const activeProduct: SimpleProduct | undefined = input.selectedProduct;

            if (!activeProduct) {
                const selectedVariantInput = new SelectedVariantInput(input.productId, input.channelId);

                return getSelectedVariant(selectedVariantInput, ctx);
            }

            return activeProduct;
        })
        .then<ProductPrice | null>((productResult: SimpleProduct | null) => {
            const projectDomain: ProjectionDomain = { ChannelId: +ctx.requestContext.apiSettings.channelId, CatalogId: +ctx.requestContext.apiSettings.catalogId };

            const activeProduct: SimpleProduct | undefined = <SimpleProduct | undefined>productResult;           
            if (activeProduct) {
                
                return getActivePricesAsync(
                    { callerContext: ctx, queryResultSettings: {} },
                    projectDomain,
                    [activeProduct.RecordId],
                    new Date(),
                    input.customerId || null,
                    affliations,
                    true
                    // @ts-ignore
                ).then(response => {
                    if(response && response.length > 0) {
                        const productPrice = response[0];

                        // If RS Verison < 9.16.0 (aka 10.0.6), customer contextual price won't be
                        // included so instead just use AdjustedPrice
                        if (semver.lt(ctx.requestContext.apiSettings.retailServerProxyVersion, '9.16.0')) {
                            productPrice.CustomerContextualPrice = productPrice.AdjustedPrice;
                        }

                        return productPrice;
                    }
                    throw new Error('[getPriceForSelectedVariantAction]Invalid response recieved from calculateProductPrice');
                });
            }

            return null;
        })
        .catch((error: Error) => {
            ctx.trace(error.message);
            ctx.telemetry.exception(error);
            ctx.telemetry.debug(`[getPriceForSelectedVariantAction]Error executing action`);
            throw new Error('[getPriceForSelectedVariantAction]Error executing action');
        });
}

export const getPriceForSelectedVariantActionDataAction = createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-price-for-selected-variant',
    action: <IAction<ProductPrice | null>>getPriceForSelectedVariantAction,
    input: createActivePriceForSelectedVariantInput
});

export default getPriceForSelectedVariantActionDataAction;