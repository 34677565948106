import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, ICreateActionContext } from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct, SearchStoreCriteria, OrgUnit, Cart } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { searchAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/OrgUnitsDataActions.g';

import { getSimpleProducts, ProductInput, getCustomer, GetCustomerInput, getFeatureState, createGetFeatureStateInput } from './index';

/**
 * Input class for activeCartWithProducts data action
 */
export class ActiveCartProductsInput implements IActionInput {
    public getCacheKey = () => `ActiveCartProducts`;
    public getCacheObjectType = () => 'ActiveCartProducts';
    public dataCacheType = (): CacheType => 'none';
}

const createInput = (inputData: ICreateActionContext) => {
    return new ActiveCartProductsInput();
};

/**
 * Calls the Retail API and returns a cart object based on the passed GetCartInput
 */
export async function getActiveCartProductsAction(input: ActiveCartProductsInput, ctx: IActionContext): Promise<SimpleProduct[]> {
    // If no cart ID is provided in input, we need to create a cart object
    if (!input) {
        throw new Error('[getActiveCartWithProducts]No valid Input was provided, failing');
    }

    const cartState = await getCartState(ctx);
    const cart = cartState.cart;

    const isQuantityLimitsFeatureIsOn: boolean = await isOrderQuantityLimitsFeatureEnabled(ctx);

    if (isQuantityLimitsFeatureIsOn) {
        return await getActiveCartProductsActionWhenQuantityLimitsFeatureIsOn(cart, ctx);
    }

    // If there are cart lines, make call to get products
    if (!cartState.hasInvoiceLine && cart && cart.CartLines && cart.CartLines.length) {
        ctx.trace('Getting cart product information...');
        return getSimpleProducts(
            <ProductInput[]>cart.CartLines.map(cartLine => {
                if (cartLine.ProductId) {
                    return new ProductInput(cartLine.ProductId, ctx.requestContext.apiSettings);
                }
                return undefined;
            }).filter(Boolean),
            ctx
        )
            .then((products: SimpleProduct[]) => {
                if (products) {
                    return products;
                } else {
                    return [];
                }
            })
            .catch((error: Error) => {
                ctx.trace(error.toString());
                ctx.telemetry.error(error.message);
                ctx.telemetry.debug(`[getActiveCartWithProducts]Unable to hydrate cart with product information`);
                throw new Error('[getActiveCartWithProducts]Unable to hydrate cart with product information');
            });
    }

    ctx.trace('[getActiveCartWithProducts]No Products Found in cart');
    return <SimpleProduct[]>[];
}

export const getActiveCartProductsActionDataAction = createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-products-in-active-cart',
    action: <IAction<SimpleProduct[]>>getActiveCartProductsAction,
    input: createInput
});

export default getActiveCartProductsActionDataAction;
async function getActiveCartProductsActionWhenQuantityLimitsFeatureIsOn(cart: Cart, ctx: IActionContext): Promise<SimpleProduct[]> {
    const productIdsByFulfillmentStoreId: Map<string, number[]> = new Map();
    let resultProducts: SimpleProduct[] = [];
    cart.CartLines?.forEach(cartLine => productIdsByFulfillmentStoreId.has(cartLine.FulfillmentStoreId!) ?
    productIdsByFulfillmentStoreId.get(cartLine.FulfillmentStoreId!)?.push(cartLine.ProductId!) :
    productIdsByFulfillmentStoreId.set(cartLine.FulfillmentStoreId!, [cartLine.ProductId!])
    );

    return Promise.all(Array.from(productIdsByFulfillmentStoreId).map(([entryFulfillmentStoreId, entryProductIds]) => {
        if (entryFulfillmentStoreId === '') {
            return getSimpleProducts(
                <ProductInput[]>entryProductIds.map(productId => {
                    if (productId) {
                        return new ProductInput(productId, ctx.requestContext.apiSettings);
                    }
                    return undefined;
                }).filter(Boolean),
                ctx
            )
                .then((products: SimpleProduct[]) => {
                    if (products) {
                        resultProducts = products.reduce((accum, product) => {
                            if (product) {
                                accum.push(product);
                            }
                            return accum;
                        }, resultProducts);
                    }
                })
        } else {
            const searchStoreCriteria: SearchStoreCriteria = {StoreNumber: entryFulfillmentStoreId};

            return searchAsync({ callerContext: ctx}, searchStoreCriteria)
                .then((orgUnits: OrgUnit[]) => getSimpleProducts(
                    <ProductInput[]>entryProductIds.map(productId => {
                        if (orgUnits && orgUnits.length) {
                            return new ProductInput(productId, ctx.requestContext.apiSettings, orgUnits[0].RecordId);
                        }
                        return undefined;
                    }).filter(Boolean),
                    ctx
                ))
                .then((products: SimpleProduct[]) => {
                    if (products) {
                        resultProducts = products.reduce((accum, product) => {
                            if (product) {
                                accum.push(product);
                            }
                            return accum;
                        }, resultProducts);
                    }
                });
        }
    })).then(() => resultProducts);
}

async function isOrderQuantityLimitsFeatureEnabled(ctx: IActionContext): Promise<boolean> {
    const defaultOrderQuantityLimitsFeatureConfig = ctx.requestContext.app?.platform?.enableDefaultOrderQuantityLimits;
    if (defaultOrderQuantityLimitsFeatureConfig === 'none') {
        return Promise.resolve(false);
    }

    const featureStates = await getFeatureState(createGetFeatureStateInput(ctx), ctx);
    const isQuantityLimitsFeatureEnabledInHq = featureStates.
        find(featureState => featureState.Name === 'Dynamics.AX.Application.RetailDefaultOrderQuantityLimitsFeature')?.IsEnabled || false;
    
    if (!isQuantityLimitsFeatureEnabledInHq) {
        return false;
    }

    if (defaultOrderQuantityLimitsFeatureConfig === 'all') {
        return Promise.resolve(true);
    }

    return getCustomer(new GetCustomerInput(ctx.requestContext.apiSettings), ctx)
        .then(customerInfo => {
            return !!customerInfo &&
                ((defaultOrderQuantityLimitsFeatureConfig === 'b2b' && customerInfo.IsB2b) ||
                (defaultOrderQuantityLimitsFeatureConfig === 'b2c' && !customerInfo.IsB2b));
        }).catch((error: Error) => {
            ctx.telemetry.warning(error.message);
            ctx.telemetry.debug('Unable to get customer info');
            return false;
        });
}
