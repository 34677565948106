import { CacheType, IAction, IActionInput, ICommerceApiSettings } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { SortColumn } from '@msdyn365-commerce/retail-proxy';
import { buildCacheKey } from '.';

import { getSearchConfigurationAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/StoreOperationsDataActions.g';

// TODO: import from commerce entities when available
// tslint:disable-next-line:interface-name
interface SearchConfiguration {
    ProductSortColumns?: SortColumn[];
}

/**
 * Action Input class for the getSearchConfiguration data action
 */
export class SearchConfigurationInput implements IActionInput {
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
      this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey('SearchConfig', this.apiSettings);
    public getCacheObjectType = () => 'SearchConfiguration';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * Creates the input required to make the retail api call
 */
export const createSearchConfigurationInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new SearchConfigurationInput(inputData.requestContext.apiSettings);
};

/**
 * Calls the Retail API
 */
export async function getSearchConfigurationAction(input: SearchConfigurationInput, ctx: IActionContext): Promise<SearchConfiguration> {
    return getSearchConfigurationAsync({ callerContext: ctx });
}

export const getSearchConfigurationActionDataAction = createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-search-configurations',
    action: <IAction<SearchConfiguration>>getSearchConfigurationAction,
    input: createSearchConfigurationInput
});

export default getSearchConfigurationActionDataAction;
