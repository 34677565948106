import { CacheType, IAction, IActionInput, ICommerceApiSettings } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { BusinessPartnerUser, BusinessPartnerOperationRequest } from '@msdyn365-commerce/retail-proxy';
import { getOperationRequestsAsync} from '@msdyn365-commerce/retail-proxy/dist/DataActions/BusinessPartnerUsersDataActions.g';
import { getCurrentBusinessPartnerUser, GetCurrentBusinessPartnerUserInput } from './get-current-business-partner-user';
import { buildCacheKey } from './utilities/utils';
import { StringExtensions } from './utilities';

/**
 * Input class for get customer data action
 */
export class GetBusinessPartnerOperationRequestsInput implements IActionInput {
    public fromDate: Date;
    public toDate: Date;
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings, fromDate: Date, toDate: Date) {
        this.apiSettings = apiSettings;
        this.fromDate = fromDate;
        this.toDate = toDate;
    }

    public getCacheKey = () => buildCacheKey(`BusinessPartnerOperationRequests-cacheKey`, this.apiSettings);
    public getCacheObjectType = () => 'BusinessPartnerOperationRequest[]';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * Creates the input required to make the retail api call
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): GetBusinessPartnerOperationRequestsInput => {
        const fromDate = new Date(Date.now() - (86400000 * 3)); // 3 days ago.

        const toDate: Date = new Date(Date.now());
        return new GetBusinessPartnerOperationRequestsInput(inputData.requestContext.apiSettings, fromDate, toDate);
};

export async function getBusinessPartnerOperationRequestsAction(input: GetBusinessPartnerOperationRequestsInput, ctx: IActionContext): Promise<BusinessPartnerOperationRequest[]> {
    if (input) {
        const userInput = new GetCurrentBusinessPartnerUserInput(ctx.requestContext.apiSettings);
        const user: BusinessPartnerUser | undefined = await getCurrentBusinessPartnerUser(userInput, ctx);

        if (user && !StringExtensions.isNullOrEmpty(user.B2BUserId)) {
            return await getOperationRequestsAsync({ callerContext: ctx }, user.B2BUserId, input.fromDate, input.toDate);
        }

        return [];
    }

    ctx.trace('[getBusinessPartnerOperationRequest]Invalid input passed to action');
    return <BusinessPartnerOperationRequest[]>[];
};

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-business-partner-operation-requests',
    action: <IAction<BusinessPartnerOperationRequest[]>>getBusinessPartnerOperationRequestsAction,
    input: createInput
});
