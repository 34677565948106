import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, ICommerceApiSettings, ICreateActionContext } from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';
import { Cart, ChannelConfiguration, LoyaltyCard, LoyaltyPointRedemptionEstimate } from '@msdyn365-commerce/retail-proxy';
import { getMaxLoyaltyPointsToRedeemForTransactionBalanceAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { getOrgUnitConfigurationAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/OrgUnitsDataActions.g';
import { getLoyalty,  GetLoyaltyCardInput } from './index';
import { buildCacheKey } from './index';

/**
 *  Input class for the getLoyaltyCard data action
 */
export class GetLoyaltyTransactionEstimationInput implements IActionInput {
    public userAccountNumber?: string;
    public apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings, userAccountNumber?: string) {
        this.userAccountNumber = userAccountNumber;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`GetLoyaltyCardTransactionEstimation-${this.userAccountNumber}`, this.apiSettings);
    public getCacheObjectType = () => 'GetLoyaltyCardTransactionEstimation';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * createInput method for the getLoyaltyCard method
 * @param inputData The input data passed to the createInput method
 */
export const createGetLoyaltyTransactionEstimationInput = (inputData: ICreateActionContext): GetLoyaltyTransactionEstimationInput => {
    const { requestContext } = inputData;
    if (!requestContext.user.isAuthenticated) {
        return new GetLoyaltyTransactionEstimationInput(inputData.requestContext.apiSettings);
    }

    return new GetLoyaltyTransactionEstimationInput(inputData.requestContext.apiSettings);
};

/**
 * The action method for the getLoyaltyCard data action
 * @param input The action input
 * @param ctx The action context
 */
export async function getLoyaltyTransactionEstimationAction(input: GetLoyaltyTransactionEstimationInput, ctx: IActionContext): Promise<LoyaltyPointRedemptionEstimate> {

    if (! ctx.requestContext.user.isAuthenticated) {
        return <LoyaltyPointRedemptionEstimate>{};
    }

    const promises: [Promise<Cart>,Promise<LoyaltyCard>, Promise<ChannelConfiguration>] = [ _getCart(ctx), _getLoyalty(input, ctx), _getOrgUnits(ctx) ];
    return Promise.all(promises)
        .then((result) => {
            if(result && result.length >=2 && result[1].CardNumber)
            {
            return getMaxLoyaltyPointsToRedeemForTransactionBalanceAsync({ callerContext: ctx }, result[0].Id, result[1].CardNumber!, result[2].Currency || '')
                .then(points => {
                    return points;
                })
                .catch(e => {
                    ctx.telemetry.exception(e);
                    ctx.telemetry.debug(`Error getting Loyalty Point Redemption Estimate`);
                    throw new Error('Error getting Loyalty Point Redemption Estimate');
                });
            }
            return <LoyaltyPointRedemptionEstimate>{};
        })
        .catch(e => {
            return <LoyaltyPointRedemptionEstimate>{};
        });
}

async function _getCart(ctx: IActionContext): Promise<Cart> {
    const cartState = await getCartState(ctx);

    return cartState.cart;
}

async function _getLoyalty(input: GetLoyaltyTransactionEstimationInput, ctx: IActionContext): Promise<LoyaltyCard> {
    const loyaltyCardInput = new GetLoyaltyCardInput(input.apiSettings);
    return getLoyalty(loyaltyCardInput, ctx);
}

async function _getOrgUnits(ctx: IActionContext): Promise<ChannelConfiguration> {
    return getOrgUnitConfigurationAsync({callerContext: ctx, queryResultSettings: {}});
}

/**
 * The getLoyaltyTransactionEstimation data action
 * Returns the loyalty card belonging to the customer
 */
export const getLoyaltyTransactionEstimationActionDataAction = createObservableDataAction<LoyaltyCard>({
    id: '@msdyn365-commerce-modules/retail-actions/get-loyalty-transaction-estimation',
    action: <IAction<LoyaltyCard>>getLoyaltyTransactionEstimationAction,
    input: <(args: ICreateActionContext) => IActionInput>createGetLoyaltyTransactionEstimationInput
});

export default getLoyaltyTransactionEstimationActionDataAction;
