import { CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICreateActionContext } from '@msdyn365-commerce/core';
import { addProductListLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductListsDataActions.g';
import { ProductListLine } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

/**
 *  Input class for the AddItemToOrderTemplate data action.
 */
export class AddItemToOrderTemplateInput implements IActionInput {
    public readonly orderTemplateId: string;
    public readonly productId: number;
    public readonly quantity: number;
    public readonly unitOfMeasure: string;

    constructor(orderTemplateId: string, productId: number, quantity: number, unitOfMeasure: string) {
        this.orderTemplateId = orderTemplateId;
        this.productId = productId;
        this.quantity = quantity;
        this.unitOfMeasure = unitOfMeasure || 'ea';
    }

    public getCacheKey = () => 'AddItemToOrderTemplateInput';
    public getCacheObjectType = () => 'AddItemToOrderTemplateInput';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * createInput method for the AddItemToOrderTemplate method.
 * @param {ICreateActionContext} inputData The input data passed to the createInput method.
 * @param {string} orderTemplateId The id of order template to update.
 * @param {number} productId The id of a product to add.
 * @param {number} quantity How many items of this product to add.
 */
export const addItemToOrderTemplateInput = (
    inputData: ICreateActionContext, orderTemplateId: string, productId: number, quantity: number, unitOfMeasure: string): AddItemToOrderTemplateInput => {

    const { requestContext } = inputData;
    if (!requestContext.user.isAuthenticated) {
        throw new Error('Unable to create order template. User is not authenticated.');
    }
    return new AddItemToOrderTemplateInput(orderTemplateId, productId, quantity, unitOfMeasure);
};

/**
 * The action method for the AddItemToOrderTemplate data action.
 * @param {AddItemToOrderTemplateInput} input The action input.
 * @param {IActionContext} ctx The action context.
 */
export async function addItemToOrderTemplateAction(input: AddItemToOrderTemplateInput, ctx: IActionContext): Promise<ProductListLine> {
    try {
        const productListLineToAdd: ProductListLine = {
            ProductListId: input.orderTemplateId,
            ProductId: input.productId,
            Quantity: input.quantity,
            UnitOfMeasure: input.unitOfMeasure
        };

        const productListLine: ProductListLine[] = await addProductListLinesAsync({
            callerContext: ctx
        }, input.orderTemplateId, [productListLineToAdd]);

        if (productListLine && productListLine.length === 1 && productListLine[0] && productListLine[0].ProductListId) {
            return productListLine[0];
        } else {
            ctx.telemetry.error('Product list line was not returned when adding it to order template');
            throw Error('Product list line was not returned when adding it to order template');
        }
    } catch (error) {
        ctx.telemetry.error('Not able to add an item to order template', error);
        throw error;
    }
}

/**
 * The AddItemToOrderTemplate Data Action.
 * Returns product list line that was added to the order template.
 */
export const addItemToOrderTemplateActionDataAction = createObservableDataAction<ProductListLine>({
    id: '@msdyn365-commerce-modules/retail-actions/order-templates/add-item-to-order-template',
    action: <IAction<ProductListLine>>addItemToOrderTemplateAction,
    input: addItemToOrderTemplateInput
});

export default addItemToOrderTemplateActionDataAction;