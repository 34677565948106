import { CacheType, IAction, IActionInput, ICommerceApiSettings } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, ICreateActionContext } from '@msdyn365-commerce/core';
import { readAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ScanResultsDataActions.g';
import { ProductDimension } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { getDimensionsForSelectedVariant, GetDimensionsForSelectedVariantInput } from './index';
import { IScanResultWithProduct } from './utilities/scan-result-with-product';
import { buildCacheKey } from './utilities/utils';

/**
 * Input class to get product from sku number
 */
export class GetScanResultInput implements IActionInput {
    public skuNumber: string;
    public channelId: number;
    public matchingDimensionValues: ProductDimension[];
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings, sku: string, channelId: number, matchingDimensionValues?: ProductDimension[]) {
        this.skuNumber = sku;
        this.channelId = channelId;
        this.matchingDimensionValues = matchingDimensionValues || [];
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`Product-${this.skuNumber}`, this.apiSettings);
    public getCacheObjectType = () => 'ProductSKU';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * CreateInput method for the getSelectedVariant data action
 * @param inputData The input data passed to the createInput method
 * @param skuuNumber skuNumber to send ScanResult API
 * @param channelId channelId required to get dimension for given product
 */
const createInput = (inputData: ICreateActionContext, skuNumber: string, channelId: number, matchingDimensionValues?: ProductDimension[]) => {
    return new GetScanResultInput(inputData.requestContext.apiSettings, skuNumber, channelId, matchingDimensionValues);
};

/**
 * Action method for the product and dimension from scan result and dimension api
 * @param input The action input class
 * @param ctx The action context
 */
export async function getScanResult(input: GetScanResultInput, ctx: IActionContext): Promise<IScanResultWithProduct | undefined> {
        return readAsync({callerContext: ctx}, input.skuNumber)
            .then(scanResults => {
                if (scanResults && scanResults.Product) {
                    return getDimensionsForSelectedVariant(new GetDimensionsForSelectedVariantInput(
                                scanResults.Product.MasterProductId ? scanResults.Product.MasterProductId : scanResults.Product.RecordId, input.channelId, input.matchingDimensionValues), ctx)
                                .then(response => {
                                    if(response && response.length > 0) {
                                        return {ScanResult: scanResults, Product: scanResults.Product, ProductDimensionFull: response};
                                    }
                                    return {ScanResult: scanResults, Product: scanResults.Product};
                    })
                    .catch((error: Error) => {
                        ctx.trace(error.message);
                        ctx.telemetry.exception(error);
                        ctx.telemetry.debug(`[getScanResult, getDimensionsForSelectedVariant]Error executing action`);
                        throw new Error('[getScanResult, getDimensionsForSelectedVariant]Error executing action');
                    });
                }

              throw new Error('Unable to get result from scan result data action.');
            })
            .catch((error: Error) => {
                ctx.trace(error.message);
                ctx.telemetry.exception(error);
                ctx.telemetry.debug(`[getScanResult]Error executing action`);
                throw new Error('[getScanResult]Error executing action');
            });
    }

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-scan-results',
    action: <IAction<IScanResultWithProduct | undefined>>getScanResult,
    input: createInput
});
