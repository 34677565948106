import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { ChannelDeliveryOption, FeatureState, OrgUnitAvailability, OrgUnitLocation, SearchArea, StoreHours } from '@msdyn365-commerce/retail-proxy';
import { getChannelDeliveryOptionsAsync, getOrgUnitLocationsByAreaAsync, getStoreHoursAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/OrgUnitsDataActions.g';
import {FeatureStateInput, getFeatureStateAction} from './get-feature-state';
import { IFullOrgUnitAvailability } from './utilities/full-org-unit-availability';

/**
 * Get selected variant action input class
 */
export class GetOrgUnitLocationsByAreaInput implements IActionInput {
    public Latitude?: number;
    public Longitude?: number;
    public Radius?: number;
    public DistanceUnitValue?: number;
    public IgnoreLocation?: boolean;

    constructor(_Latitude?: number, _Longitude?: number, _Radius?: number, _DistanceUnitValue?: number, _IgnoreLocation?: boolean) {
        this.Latitude = _Latitude;
        this.Longitude = _Longitude;
        this.Radius = _Radius;
        this.DistanceUnitValue = _DistanceUnitValue;
        this.IgnoreLocation = _IgnoreLocation;
    }

    public getCacheKey = () => `GetOrgUnitLocationsByAreaInput`;
    public getCacheObjectType = () => 'GetOrgUnitLocationsByAreaInput';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * CreateInput method for the getSelectedVariant data action
 * @param inputData The input data passed to the createInput method
 */
export const createGetOrgUnitLocationsByAreaInput = (inputData: ICreateActionContext<IGeneric<IAny>>): GetOrgUnitLocationsByAreaInput => {
    return new GetOrgUnitLocationsByAreaInput();
};

/**
 * Calls the Retail Feature State API and returns a list of feature with isEnabled flag.
 */
export async function getFeatureState(context: IActionContext): Promise<FeatureState[]> {
    return getFeatureStateAction(new FeatureStateInput(), context);
}

/**
 * Action method for the getSelectedVariant data action
 * @param input The action input class
 * @param ctx The action context
 */
export async function getOrgUnitLocationsByArea(
    input: GetOrgUnitLocationsByAreaInput,
    ctx: IActionContext
): Promise<IFullOrgUnitAvailability[] | undefined> {

    if ((input.Radius === undefined || !input.Latitude || !input.Longitude) && !input.IgnoreLocation) {
        // No valid location we want to return empty array so module can show no locations message
        return [];
    }

    const searchArea: SearchArea = {
        Latitude: input.Latitude,
        Longitude: input.Longitude,
        Radius: input.Radius,
        DistanceUnitValue: input.DistanceUnitValue || 0 // 0 is miles
    };

    const featureState = await getFeatureState(ctx);
    const retailMulitplePickupMFeatureState = featureState?.find(item => item.Name === 'Dynamics.AX.Application.RetailMultiplePickupDeliveryModeFeature')?.IsEnabled;

    return getOrgUnitLocationsByAreaAsync({ callerContext: ctx }, searchArea)
        .then(async (stores: OrgUnitLocation[]) => {
            let locationDeliveryOptions: ChannelDeliveryOption[] | undefined = [];
            // if mulitple pickup mode is enable then call getchanneldeliveryoption
        if(retailMulitplePickupMFeatureState) {
           const orgUnitChannel = stores.map(store => store.ChannelId);
           locationDeliveryOptions = await _getLocationPickUpDeliveryModes(orgUnitChannel, ctx);
        }
           const locationPromiseList = stores.map(store => {
               const locationDeliveryOption = locationDeliveryOptions?.find(_channeldeliveryoption => _channeldeliveryoption.ChannelId === store.ChannelId);
               return _getLocationWithHours(store, locationDeliveryOption, ctx);
            });
            return Promise.all(locationPromiseList);
        })
        .catch((error: Error) => {
            ctx.trace('[GetOrgUnitLocationsByArea] error getting Locations');
            ctx.trace(error.message);
            ctx.telemetry.error(error.message);
            ctx.telemetry.debug(`[GetOrgUnitLocationsByArea] error getting Locations`);
            return [];
        });
}

/**
 * Action method that obtains the store information
 * @param orgUnitLocation The org unit location
 * @param storeMap a map that contains store information group by the inventory location id
 * @param ctx The action context
 */
async function _getLocationWithHours(
    orgUnitLocation: OrgUnitLocation,channelDeleiveryOptions: ChannelDeliveryOption | undefined,
    ctx: IActionContext): Promise<IFullOrgUnitAvailability> {
    if (!orgUnitLocation || !orgUnitLocation.OrgUnitNumber) {
        return { OrgUnitAvailability: undefined };
    }

    return getStoreHoursAsync({ callerContext: ctx }, orgUnitLocation.OrgUnitNumber)
        .then((hours: StoreHours) => {

            const availability: OrgUnitAvailability = {
                OrgUnitLocation: orgUnitLocation
            };

            if (hours && !(hours instanceof Error)) {
                return { OrgUnitAvailability: availability, StoreHours: hours, OrgUnitPickUpDeliveryModes: channelDeleiveryOptions};
            }

            return { OrgUnitAvailability: availability, OrgUnitPickUpDeliveryModes: channelDeleiveryOptions };
        })
        .catch((error: Error) => {
            ctx.trace('[GetFullAvailableInventoryNearby] error getting availability with hours');
            ctx.trace(error.message);
            ctx.telemetry.exception(error);
            ctx.telemetry.debug(`[GetFullAvailableInventoryNearby] error getting availability with hours`);
            return { OrgUnitAvailability: {} };
        });
}

/**
 * Action method that obtains the store information
 * @param channelCollection The org unit channel Id list
 * @param ctx The action context
 */
async function _getLocationPickUpDeliveryModes(
    channelCollection: (number| undefined)[],
    ctx: IActionContext): Promise<ChannelDeliveryOption[] | undefined> {
        if (channelCollection?.length === 0  || channelCollection === undefined) {
            return undefined;
        }

        const channelIdList: number[] = [];
         channelCollection?.map(id => {
             if(id !== undefined) {
                 return channelIdList.push(id);
             }
         });

           // to get all channel pickup delivery mode filterOption should be 4
    return getChannelDeliveryOptionsAsync({ callerContext: ctx }, channelIdList, 4)
        .then((channelDeliveryOptions: ChannelDeliveryOption[]) => {

            if (channelDeliveryOptions && !(channelDeliveryOptions instanceof Error)) {
                return channelDeliveryOptions;
            }

            return undefined;
        })
        .catch((error: Error) => {
            ctx.trace('[GetFullAvailableInventoryNearby][getChannelDeliveryOptionsAsync] error getting availability with channel delivery options');
            ctx.trace(error.message);
            ctx.telemetry.exception(error);
            ctx.telemetry.debug(`[GetFullAvailableInventoryNearby] [getChannelDeliveryOptionsAsync] error getting availability with channel delivery options`);
            return [];
        });
}

/**
 * The complete getOrgUnitLocationsByArea data action
 */
export const getOrgUnitLocationsByAreaDataAction = createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-store-location-information',
    action: <IAction<IFullOrgUnitAvailability[] | undefined>>getOrgUnitLocationsByArea,
    input: createGetOrgUnitLocationsByAreaInput
});

export default getOrgUnitLocationsByAreaDataAction;