import { CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICreateActionContext } from '@msdyn365-commerce/core';
import { IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { OrderTemplate } from '../utilities/order-templates';
import { QueryResultSettingsProxy } from '../utilities/QueryResultSettingsProxy';
import getFullOrderTemplates, { GetFullOrderTemplatesInput } from './get-full-order-templates';

/**
 *  Input class for the GetOrderTemplates data action
 */
export class GetOrderTemplatesPreviewInput implements IActionInput {
    public queryResultSettings: IQueryResultSettings;

    constructor(queryResultSettings: IQueryResultSettings) {
        this.queryResultSettings = queryResultSettings;
        this.queryResultSettings.count = true;
        this.queryResultSettings.Paging!.Top = 4;
    }

    public getCacheKey = () => 'GetOrderTemplatesPreview';
    public getCacheObjectType = () => 'GetOrderTemplatesPreview';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * createInput method for the GetOrderTemplate method
 * @param inputData The input data passed to the createInput method.
 */
export const createGetOrderTemplatesPreviewInput = (inputData: ICreateActionContext): GetOrderTemplatesPreviewInput => {
    const queryResultSettings: IQueryResultSettings = QueryResultSettingsProxy.fromInputData(inputData).QueryResultSettings;
    return new GetOrderTemplatesPreviewInput(queryResultSettings);
};

/**
 * The action method for the GetOrderTemplatesPreview data action
 * @param input The action input
 * @param context The action context
 */
export async function getOrderTemplatesPreview(input: GetOrderTemplatesPreviewInput, context: IActionContext): Promise<OrderTemplate[]> {
    return getFullOrderTemplates(new GetFullOrderTemplatesInput(input.queryResultSettings), context);
}

/**
 * The GetOrderTemplatesPreview Data Action
 * Returns order templates
 */
export const getOrderTemplatesPreviewDataAction = createObservableDataAction<OrderTemplate[]>({
    id: '@msdyn365-commerce-modules/retail-actions/order-templates/get-order-templates-preview',
    action: <IAction<OrderTemplate[]>>getOrderTemplatesPreview,
    input: createGetOrderTemplatesPreviewInput
});

export default getOrderTemplatesPreviewDataAction;