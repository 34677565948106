import { CacheType, IAction, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { generateProductImageUrl, getSelectedProductIdFromActionInput } from './utilities/utils';
import { BypassCache } from '@msdyn365-commerce/retail-proxy'
import { getByIdAsync, getVariantsByDimensionValuesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { ProductDimension, SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

/**
 * Get selected variant action input class
 */
export class SelectedVariantInput implements IActionInput {
    public productId: number;
    public channelId: number;
    public matchingDimensionValues: ProductDimension[];
    public bypassCache? : BypassCache;

    constructor(productId: number, channelId: number, matchingDimensionValues?: ProductDimension[], bypassCache? : BypassCache) {
        this.productId = productId;
        this.channelId = channelId;
        this.matchingDimensionValues = matchingDimensionValues || [];
        this.bypassCache = bypassCache;
    }

    public getCacheKey = () => `SelectedVariant`;
    public getCacheObjectType = () => 'SimpleProduct';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * CreateInput method for the getSelectedVariant data action
 * @param inputData The input data passed to the createInput method
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): SelectedVariantInput => {
    const productId = getSelectedProductIdFromActionInput(inputData);

    if (productId) {
        return new SelectedVariantInput(+productId, +inputData.requestContext.apiSettings.channelId, []);
    } else {
        throw new Error('Unable to create SelectedVariantInput, no productId found on module config or query');
    }
};

/**
 * Action method for the getSelectedVariant data aciton
 * @param input The action input class
 * @param ctx The action context
 */
async function getSelectedVariantAction(input: SelectedVariantInput, ctx: IActionContext): Promise<SimpleProduct | null> {
    let product: SimpleProduct | null = null;

    const response = await getByIdAsync({ callerContext: ctx, bypassCache: input.bypassCache }, input.productId, input.channelId);

    const baseProduct: SimpleProduct = Array.isArray(response) ? response[0] : response;

    // Need to dereference this before editing it. Otherwise we might not
    // properly get the mobx events because if things aren't properly observable
    // they won't fire when you set them, and then if you don't deref the value in
    // the cache will match the value when you try to save it, so it won't detect any
    // changes there either
    product = { ...baseProduct };

    if (product) {
        let baseProductHadUnmatchedDimension: boolean = false;
        if (product.Dimensions) {
            product.Dimensions.map(dimension => {
                const matchedTargetDimension = input.matchingDimensionValues.find(
                    targetDimension => targetDimension.DimensionTypeValue === dimension.DimensionTypeValue
                );

                if (matchedTargetDimension) {
                    dimension.DimensionValue = matchedTargetDimension.DimensionValue;
                } else {
                    baseProductHadUnmatchedDimension = true;
                }
            });
        }

        if (!baseProductHadUnmatchedDimension && input.matchingDimensionValues.length > 0) {
            const variants = await getVariantsByDimensionValuesAsync(
                { callerContext: ctx, bypassCache: input.bypassCache, queryResultSettings: {} },
                baseProduct.RecordId,
                input.channelId,
                input.matchingDimensionValues
            );

            if (variants && variants.length > 0) {
                product = variants[0];
            }
        }

        const newImageUrl = generateProductImageUrl(product, ctx.requestContext.apiSettings);

        if (newImageUrl) {
            product.PrimaryImageUrl = newImageUrl;
        }
    }

    return product;
}

/**
 * The complete getSelectedVariant data action
 */
export const getSelectedVariantActionDataAction = createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-selected-variant',
    action: <IAction<SimpleProduct | null>>getSelectedVariantAction,
    input: createInput
});

export default getSelectedVariantActionDataAction;